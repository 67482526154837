.app-create-project {
  
  display: block;
  width: 100%;
  max-width: 1188px;
}
.app-create-project input {
  letter-spacing: 0.02em;
}
.app-create-project .colum-left {
  width: 80%;
  background-color: white;
}
.app-create-project .colum-left .content-left {
  padding: 50px 70px;
}
.app-create-project .colum-left app-label {
  margin-top: 3.8em;
  display: block;
}
.app-create-project .colum-left app-label:first-child {
  margin-top: 0;
}
.app-create-project .colum-left .app-label {
  margin-top: 3.8em;
  display: block;
}
.app-create-project .colum-left .app-label:first-child {
  margin-top: 0;
}
.app-create-project .colum-left bt-button {
  margin-top: 40px;
  float: right;
}
.app-create-project .colum-right {
  flex: 0 1 44%;
  background-color: #001659;
}
.app-create-project .colum-right .content-right {
  padding: 50px 70px;
}
.app-create-project .colum-right .content-right .headline {
  margin-bottom: 5px;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: normal;
  display: block;
  color: white;
  letter-spacing: 0.1em;
}
.app-create-project .colum-right .content-right h2 {
  
  margin: 0;
  padding: 0;
  color: white;
  font-size: 3.4em;
  margin-bottom: 0.65em;
}
.app-create-project .colum-right .content-right p {
  color: white;
  margin: 0;
  margin-top: -10px;
  letter-spacing: 0.1em;
  line-height: 2.2em;
}
.app-create-project .slides{
  margin-top: 40px;
  float: left;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #7a7a7a;
  text-transform: capitalize;
}
.app-create-project .slides img,
.app-create-project .slides input {
  width: 30px;
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
}

.btn-right {
  margin-top: 40px;
  float: right;
}
label.dropdown{
  font-weight: 500;
  
  letter-spacing: 0.1em;
  color: #001659;
  font-size: .86em;
  text-transform: uppercase;
}
.dropdown .mandatory {
  margin-left: 3px;
  color: #ff5e15;
}

.not-top {
  margin-top: 3.8em;
  display: block;
}

.dropdown select {
    background-color: transparent;
    margin-top: 10px;
    display: block;
    border:none;
    padding: 1.5em 0;
    color: var(--gray-700);
    
    border-bottom: 1px solid #e2e2ea;
    letter-spacing: 0.02em;
    width: 100%;
    padding-right: 70px;
    background-image: url("../../assets/images/dropdown-icon.png");
    background-size: 15px;
    background-position: 98% 50%;
}
.dropdown select option{
  border: none;
  border-bottom: 1px solid #e2e2ea;
}
.app-create-project .suggestionText{
  color: #545454;
  font-size: 0.86em;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-left: 5px;
}

.app-create-project .error{
  text-align: left;
}
#create-project-modal .error-show {
  width: 100%;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  color: #fe5150;
  font-size: 0.85em;
  text-align: center;
  opacity: 1;
  margin-top: -10px;
  text-align: left;
}
.add-project-field {
  margin-bottom: 10px;
}