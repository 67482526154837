.app-headsup {
  background-color: #ff5e15;
  display: inline-block;
  position: fixed;
  margin-top: 20px;
  z-index: 1;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  padding-top: 8px;
  
  -webkit-box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.14);
  font-size: 0.9em;
  opacity: 0;
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
  pointer-events: none;
  cursor: pointer;
}
.app-headsup.animation-enabled {
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;
}
.app-headsup.show {
  opacity: 1;
  pointer-events: all;
}
@-webkit-keyframes action {
  0% {
    transform: translateY(-5px);
 }
  100% {
    transform: translateY(10px);
 }
}
@keyframes action {
  0% {
    transform: translateY(-5px);
 }
  100% {
    transform: translateY(10px);
 }
}
