.app-project-overview bt-image-blueprint {
  position: absolute;
  top: 95px;
  left: 15px;
}
.app-project-overview .app-project-overview-item {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}
.app-project-overview .overview-item-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 15px;
}
.app-project-overview .overview-item-wrapper .item {
  margin: 0px;
  left: 0;
  width: 50%;
  box-sizing: border-box;
  padding: 15px 15px;
}

@media only screen and (min-device-width: 1200px) {
  .app-project-overview .overview-item-wrapper .item {
    flex: 0 0 50%;
 }
}

.empty-project {
  margin-top: 30vh;
  margin-left: 30vw;
}

.empty-project .arrow {
  position: absolute;
  right: 95px;
  top: 175px;
  width: auto;
  height: 35vh;
  transform: translateX(22%);
}

.empty-filtered-project {
  margin-top: 10vh;
  margin-left: 50vw;
  transform: translateX(-50%);
}

.sort-order-selector {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--gray-700);
  font-size: 14px;
}

.sort-order-selector .trigger {
  display: block;
}

.sort-order-selector .trigger strong {
  font-weight: 600;
}

.sort-order-selector .trigger svg {
  vertical-align: middle; 
}

.requestsContainer > div span {
  white-space: nowrap;
}

.projectAreaCommentsContainer {
  position: relative;
  display: grid;
  grid-template-columns: 20px auto;
  grid-template-rows: 100%;
  background-color: white;
  align-items: center;
  gap: 8px;
  padding: 8px;
  height: 40px;
  overflow: hidden;
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  max-width: 400px;
  width: max-content;
  transition: background-color 300ms ease-out;
  cursor: pointer;
}

.projectAreaCommentsContainer p {
  margin: 0;
}

.projectAreaCommentsContainerDots {
  display: flex;
  align-items: center;
  width: 20px;
  height: 100%;
  padding-left: 2px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  background-color: white;
  transition: background-color 300ms ease-out;
}

.projectAreaCommentsContainer:hover,
.projectAreaCommentsContainer:hover .projectAreaCommentsContainerDots {
  background-color: var(--gray-100);
}
