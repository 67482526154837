.rating-input {
  width: 100%;
  height: 240px;
  border: 1px solid var(--gray-300);
  margin-top: 8px;
}

.rating-input.comment {
  width: 100%;
  height: 180px;
  margin-top: 0px;
}

.minStarLabel {
  padding-right: 50px;
  padding-left: 7px;
  margin-top: -5px;
}

.maxStarLabel {
  padding-right: 7px;
  margin-top: -5px;
}

.erfaRating {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 10px;
}

.fa-2x {
  font-size: 1.5em;
}

.rating-div {
  align-items: center;
  display: flex;
}

.rating-btn {
  float: right;
}

.rating-right{text-align: right;}

.icon-margin{
  margin-bottom: -8px;
  margin-right: 10px;
}

.rating-form-card {
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  padding: 32px;
  font-family: var(--primaryFont);
  color: var(--gray-700);
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  max-width: 60em;
}

.rating-form-card .header label {
  white-space: nowrap;
  color: #171724;
  font-size: 24px;
}

.rating-form-card .container-quo {
}