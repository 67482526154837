.container {
  position: relative;
  justify-content: space-between;
  background-color: var(--white);
  border: 1px solid var(--gray-300);
  border-radius: 6px;
  align-items: center;

  display: flex;

  
  &.onFocusStyles:not(.hasContent) {
    opacity: 0;
  }

  &.onFocusStyles {
    background-color: transparent;
    border-color: transparent;
    transition: opacity 350ms, background-color 350ms, border-color 350ms;
  }

  &.onFocusStyles.hasContent,
  &.onFocusStyles:hover:not(.hasContent)
  {
    opacity: 1;
    background-color: var(--gray-50);
    border-color: var(--gray-50);
    transition: opacity 350ms, background-color 350ms, border-color 350ms;
  }

  &.onFocusStyles:focus-within,
  &.onFocusStyles:focus-within:hover {
    opacity: 1;
    background-color: var(--white);
    border-color: var(--gray-300);
  }

  .unit {
    margin: 0px 8px;
    color: var(--gray-500);
  }

  &.error {
    border-color: var(--red-500);
  }

  &:first-of-type input {
    font-weight: 500;
  }

  .field {
    width: 100%;
    height: 38px;
    padding: 0 10px;

    background-color: transparent;

    &::placeholder {
      color: var(--gray-500);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &.textarea {
      min-height: 100px;
      max-height: 200px;
      resize: vertical;
      padding-top: 7px;
      color: var(--gray-700);
    }
  }

  .fieldSelector {
    width: 14.5em;
    background-color: transparent;
    border: none;
    
    & > div:first-of-type {
      border: none;
      box-shadow: none;
      height: 100%;
      padding: 5px 10px;
      
      & > div > div {
        font-weight: 400;
      }
    }

    & + .field {
      padding-left: 0px;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 10px;
    --g: 100%;
    transform: translateY(-50%);

    & + .field {
      padding-left: 40px;
    }
  }
}

.viewOnlyContainer,
.container input,
.container textarea {
  color: var(--gray-700);
  font-size: var(--fontSize-14);
  margin: 0;
}

.label {
  color: var(--gray-700);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 7px;
}

.required {
  color: var(--orange-500);
  margin-left: 3px;
} 
