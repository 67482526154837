.container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}