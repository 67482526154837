.container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.backButtonContainer {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 0;
  background-color: var(--orange-500);
  display: grid;
  place-items: center;
  cursor: pointer;

  svg {
    transform: rotate(45deg);
  }

  div, a {
    display: grid;
    place-items: center;
  }
}

.pathContainer {
  display: flex;
  gap: 15px;
  align-items: center;

  span {
    color: var(--gray-500);
    font-weight: 600;
    font-size: 18px;
  }

  span:last-child {
    color: var(--gray-900);
  }
}