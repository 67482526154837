.container {
  display: grid;
  align-items: center;
  margin: 30px;
  gap: 20px;

  .content {
    min-width: 600px;
    width: 100%;
    max-width: 1180px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  :global(.MuiTableCell-head:first-child) {
    border-top-left-radius: 6px;
  }

  :global(.MuiTableCell-head:last-child) {
    border-top-right-radius: 6px;
  }

  :global(.MuiTableBody-root) :global(.MuiTableRow-root) :global(.name) {
    color: var(--gray-900);
    font-size: var(--fontSize-14);
    font-weight: var(--weight-600);
  }

  :global(.MuiTableBody-root) :global(.MuiTableRow-root) {
    color: var(--gray-700);
    font-size: var(--fontSize-14);
    font-weight: var(--weight-400);
  }

  :global(.MuiTableCell-head) {
    color: var(--gray-900) !important;
    font-weight: 500;
    padding: 10px 16px !important;
    position: static;
    background-color: var(--gray-50) !important;
  }
  
  :global(.MuiTableBody-root) :global(.MuiTableRow-root:hover) {
    background-color: hsla(0,0%,96%,.4)!important;
    cursor: pointer;
}

  :global(.MuiTableRow-hover)+:global(.MuiTableRow-hover), :global(.MuiTableRow-hover:first-child) {
    border-top: 1px solid var(--gray-200)!important;
  }
  .filterSelector {
    width: 25%;
  }
}