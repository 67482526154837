.container{
  height: 100vh;
  position: relative;

.subContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 80vh;
  gap: 10em;

  img {
    width: 425px;
    height: 347px;
  }

  p {

    &:first-child {
      color: var(--brandBlack);
      max-width: 255px;
      font-size: 35px;
      margin-top: 0;
      letter-spacing: 0.02em;
      line-height: 40px;
      font-weight: 700;
    }
    &:last-child {
      color: var(--gray-900);
      max-width: 350px;
    }
  }

}
  .footer {
    width: 100%;
    height: 134px;
    background-color: var(--gray-800);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    flex-direction: column;

    p{
      color: var(--white);
      font-size: var(--fontSize-14);

      a {
        text-decoration: none;
        color: inherit;
      }
    }

  }
}