.accSelector-container,
.accSelector__placeholder {
  font-size: 14px;
}
.accSelector__value-container {
  padding: unset !important;
}
.accSelector__indicator-separator {
  display: none;
}
.accSelector__indicator {
  padding: 0 !important;

  svg {
    fill: var(--gray-700);
  }
}
.accSelector__control {
  width: 100%;
  min-height: unset !important;
  border-radius: 6px !important;
  padding: 5px 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-color: var(--gray-300) !important;
}
.accSelector__option {
  display: flex !important;
  align-items: center !important;
}
.accSelector__menu {
  z-index: 100 !important;
}
.accSelector__single-value {
  color: var(--gray-700);
}

.mini {
  width: 75px;
  height: 22px;
  margin: 5px 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  .accSelector__control {
    padding: 0 7px;
  }
  .accSelector__value-container {
    justify-content: center;
    height: 100%;
  }
  .accSelector__option {
    justify-content: center;
  }
}

.selector-label {
  color: var(--gray-700);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 7px;
}

.selector-label-required {
  color: var(--orange-500);
  margin-left: 3px;
}

.error-selector {
  color: var(--red-500);
  font-size: var(--fontSize-12);
  margin: -5px 0px 10px 0px;
}

.selector-error {
  .accSelector__control {
    border-color: var(--red-500) !important;
  }

  .accSelector__indicator svg {
    fill: var(--red-500);
  }
}