.container {
  display: flex;
  flex-wrap: wrap;
  
  gap: 16px;

  & > div:nth-child(odd) {
    width: calc(50% - 16px);
  }
  & > div:nth-child(even) {
    width: calc(25% - 16px);
    margin: 0;
  }
}