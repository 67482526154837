.reasonBox {
  position: absolute;
    /* left: 28%;
    top: 19%; */
    left: 0;
    top: 78%;
    width: 450px;
    background: #fff;
    height: 290px;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 1px 6px #9E9E9E;
    z-index: 1300;
}
.blackList-main-container{
  display: flex;
  align-items: center;
  position: relative;
}
.reasonBoxInput {
  width: 75%;
  height: 34px !important;
  border-bottom: 1px solid #9E9E9E;
}
.inputBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}
.btn-right.reasonBox-btn {
  margin-top: 0px !important;
  float: right;
  padding: 8px 2em !important;
}
.reasonList {
  margin-top: 16px;
  height: 190px;
  word-wrap: break-word;
  overflow-y: scroll;
}
.reasonDate {
  color: var(--gray-500);
  font-size: 12px;
  font-weight: 400;
}
.reasonListItem {
  color: var(--gray-900);
  font-weight: 400;
  font-size: 14px;
}
.reasonListItem span {
  display: block;
}
.cancelReasonBox {
  margin: 0px;
  cursor: pointer;
  position: absolute;
  right: 19px;
  top: 14px;
  transform: rotate(45deg);
}