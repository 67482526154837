

.app-add-area {
  
  display: block;
  width: 100%;
  margin: auto;
}

.app-add-area .modal--lg--style {
  max-width: calc(100%);
  width: 100%;
}

.app-add-area .colum-left {
  padding: 20px;
  background-color: white;
  width: 100%;
  min-width: 300px;
}

.app-add-area .colum-left .content-left {
  padding: 50px;
}

.app-add-area .colum-right {
  background-color: #001659;
  width: 600px;
  padding: 5px;
}

.app-add-area .colum-right .content-right {
  height: 100%;
}


.app-add-area .colum-right .content-right .step-1-right h2 {
  
  color: white;
  font-size: 35px;
  margin-left: 30px;
  margin-top: 50px;
}

.app-add-area .app-supplier-database-mini .dataTables_wrapper .dataTables_paginate {
  margin-top: 0;
}

.app-add-area .colum-left .content-left .step-2-left .bt-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.app-add-area-items ul {
  margin-right: 30px;
  margin-block-start: 0;
  margin-block-end: 0;
}

.app-add-area-items .app-add-area-item {
  position: relative;
  padding: 0;
  margin: 0;
}

/* Areas Category Modal - Areas list */
.app-add-area-items .app-add-area-item-label {
  display: inline-block;
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-weight: bold;
  margin: 25px 0 5px;
}

.app-add-area-items .search-bar {
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 25px;
}

.app-add-area-items .search-bar svg {
  position: absolute;
  top: calc(50% - 7px);
  left: 8px;
  width: 14px;
  height: 14px;
}

.app-add-area-items .search-bar input,
.app-add-area-items .add-area-input input {
  background: transparent;
  min-width: 250px;
  border: 1px solid #000;
  padding: 4px 0px 4px 30px;
  color: #000;
}

.app-add-area-items .search-bar input::placeholder,
.app-add-area-items .add-area-input input::placeholder {
  color: #6e6e6e;
}

.app-add-area-items .add-area-input {
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 25px;
}

.app-add-area-items .add-area-input svg {
  position: absolute;
  top: calc(50% - 8px);
  left: 8px;
  width: 22px;
  height: 22px;
}

.app-add-area-items .add-area-input bt-button {
  margin-left: 10px;
  padding: 7px 20px;
}

/* Areas Category Modal - SubArea form and Suggestions */
.app-add-area-items .sub-area-add-form {
  width: 100%;
  position: relative;
}

.app-add-area-items .sub-area-add-form .form {
  display: flex;
  height: 30px;
  align-items: center;
  flex-direction: row;
}

.app-add-area-items .sub-area-add-form .form input {
  margin-right: 5px;
  color: #000;
  padding: 3px 5px;
  min-width: 160px;
  font-size: 13px;
}

.app-add-area-items .sub-area-add-form .form bt-button {
  padding: 3px 20px;
  top: -20px;
}

.app-add-area-items .new-area-name-container {
  padding: 40px;
  height: 300px;
}

.new-area-name-container .bt-check label:after {
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  margin: 0 5px;
  position: relative;
  bottom: 10px;
  content: "";
  display: block;
  width: calc(100% - 15px);
  transform: scaleX(0);
  height: 1px;
  background-color: #ff5e15;
}

.new-area-name-container .bt-check.checked label:after {
  transform: scaleX(1);
}

.new-area-name-container .bt-check.checked .checkmark {
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.5s both;
}

.new-area-name-container .bt-check.checked .checkmark__circle {
  animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.new-area-name-container .bt-check.checked .checkmark__check {
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
}

.new-area-name-container .checkmark {
  position: absolute;
  top: 50%;
  margin-top: -13px;
  margin-left: -4px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  stroke-width: 4;
  stroke: #ff5e15;
  stroke-miterlimit: 10;
}
.new-area-name-container .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 3;
  stroke-miterlimit: 10;
  stroke: #ff5e15;
  fill: none;
}
.new-area-name-container .checkmark__check {
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
}
.bt-modal-close-area {
  top: 15px;
  right: 15px;
}