.page-internal-chat-member {
    padding: 30px;
    -webkit-box-shadow: -6px 0px 21px -10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -6px 0px 21px -10px rgba(0, 0, 0, 0.2);
    box-shadow: -6px 0px 21px -10px rgba(0, 0, 0, 0.2);
}
.page-internal-chat-member app-label {
    display: block;
    margin-bottom: 40px;
}
.page-internal-chat-member app-label label {
    font-size: 0.86em;
    color: #7c8097;
}
.page-internal-chat-member app-label input {
    font-size: 2em;
    font-weight: normal;
}
.page-internal-chat-member .content-left app-label:last-child {
    margin-bottom: 0;
}
.page-internal-chat-member .employees .employee {
    margin-top: 10px;
    display: flex;
    align-items: center;
}
.page-internal-chat-member .employees .employee bt-avatar {
    font-size: 1.2em;
    width: 40px;
    height: 40px;
}
.page-internal-chat-member .employees .employee label {
    margin-left: 10px;
    font-size: 1.2em;
    color: #001659;
    text-transform: none;
    white-space: nowrap;
    max-width: calc(100% - 55px);
    overflow: hidden;
    text-overflow: ellipsis;
}
.page-internal-chat-member app-label p {
  font-size: 2em;
  font-weight: normal;
  color: #001659;
  margin-top: 10px;
  border-bottom: 1px solid #e2e2ea;
  padding: 1px 2px;
  
}
