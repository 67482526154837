.form {
  --size: 18px;

  @keyframes ldsripple {
    0% {
      top: calc(var(--size) / 2);
      left: calc(var(--size) / 2);
      width: 0;
      height: 0;
      opacity: 0;
    }
  
    4.9% {
      top: calc(var(--size) / 2);
      left: calc(var(--size) / 2);
      width: 0;
      height: 0;
      opacity: 0;
    }
  
    5% {
      top: calc(var(--size) / 2);
      left: calc(var(--size) / 2);
      width: 0;
      height: 0;
      opacity: 1;
    }
  
    100% {
      top: 0px;
      left: 0px;
      width: var(--size);
      height: var(--size);
      opacity: 0;
    }
  }

  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  .organizationName {
    font-size: 14px;
    color: var(--gray-700);

    .spinner {
      display: inline-block;
      position: relative;
      width: var(--size);
      height: var(--size);

      div {
        position: absolute;
        border: 1px solid var(--gray-700);
        opacity: 1;
        border-radius: 50%;
        animation: ldsripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

        &:nth-child(2) {
          animation-delay: -0.5s;
        }
      }
    }
  }
  
  h3 { font-size: 18px; font-weight: 500; color: var(--gray-900); }
  h4 { font-size: 14px; font-weight: 500; color: var(--gray-900); }
  p  { font-size: 14px; font-weight: 400; color: var(--gray-700); }

  .recipients {
    display: flex;
    flex-direction: column;

    .recipient {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      padding-right: 48px;
      position: relative;

      &>svg {
        position: absolute;
        top: 24px;
        right: 14px;

        cursor: pointer;
      }

      label { display: flex; gap: 16px; align-items: baseline; }
      label>div { flex-grow: 1; }
      label>div:first-child { width: 10em; flex-grow: 0; text-align: right;  }
    }

    .recipient + .recipient {
      border-top: 1px solid var(--gray-200);
    }
  }
}

.background {
  position: fixed;
  left: unset;
  right: 0;
  width: calc(50% + 5px);
}

@media only screen and (max-width: 768px) {
  .form {
    .recipients {

      .recipient {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 24px;
        padding-top: 24px;
        position: relative;

        &>svg {
          position: absolute;
          top: 20px;
          right: 14px;

          cursor: pointer;
        }

        label { flex-direction: column; align-items: flex-start; text-align: left; }
        label>div { flex-grow: unset; }
        label>div:first-child { width: unset; flex-grow: unset; text-align: unset;  }
      }
    }
  }
}
