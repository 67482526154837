.tableCustomizationContainer {
  position: relative;
}
.tableCustomizationContainer .tableCustomizationMenu {
  position: absolute;
  top: calc(100%);
  right: 0;

  display: none;
  flex-direction: column;
  padding: 0 10px;
  padding-top: 10px;
  width: 350px;
  background-color: var(--gray-100);
  border: 1px solid var(--gray-300);

  border-radius: 4px;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.1);

  z-index: 5;
}
.tableCustomizationContainer .tableCustomizationMenu.open {
  display: flex;
}

.tableCustomizationContainer .tableCustomizationMenu > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  gap: 10px;
  
  height: 46px;
  border: 1px solid var(--gray-300);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  border-radius: 6px;
  color: var(--gray-700);
}
.tableCustomizationContainer .tableCustomizationMenu > li > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.tableCustomizationContainer label.ar-label.ar-label-checkbox .react-toggle {
  margin: 0;
}