.app-chat-messages {
    display: block;
}
.app-chat-messages .no-conversation {
    text-align: center;
}
.app-chat-messages .no-conversation bt-avatar {
    width: 100px;
    height: 100px;
    font-size: 2em;
}
