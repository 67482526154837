.collapsible-card-generic {
  border-radius: 4px;
  border: 1px solid var(--gray-300);
  background-color: var(--white);
}

@keyframes clipContent {
  to, from {
    overflow-y: clip;
  }
}
.collapsible-content-generic {
  overflow-y: clip;
  overflow-x: visible;
  transition: height 0.2s ease-in-out;
}
.collapsible-content-generic.isOpen {
  animation: 0.2s clipContent;
  overflow-y: visible;
}
.title-text-generic {
  display: block;
  font-size: 1em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.collapsible-header-generic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 26px;
  height: 90px;
  border-bottom: 1px solid var(--gray-300);
  transition: border-width 200ms;
}
.rotate-center-generic {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.rotate-center-generic.down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate-center-generic.up {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.collapsible-icon-container-generic {
  cursor: pointer;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: var(--gray-400);
}

.remove-border {
  border-width: 0;
}