
.head {
  font-size: 0.8em;
}

.wrapper .foot {
  color: #9399a7;
  font-size: 11px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}