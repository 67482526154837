.templateItem {
  background-color: var(--white) !important;
  border-radius: 8px;
  --tw-ring-offset-shadow: 0 0 0 0 #fff;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.01), var(--tw-shadow, 0 0 #0000), var(--tw-ring-offset-shadow);
  border: 0;

  position: relative;
  display: grid;
  place-items: center;

  cursor: pointer;

  span {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--gray-700);

    svg {
      fill: var(--gray-700);
    }
  }
  
  &:hover {
    background-color: rgba(255, 255, 255, .7);
  }

  .mouseContainer {
    position: fixed;
    padding: 8px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
    color: #374151;
    border-radius: 8px;
    background-color: var(--gray-200);
    max-height: 300px;
    overflow-y: auto;
    z-index: 100;

    & > div {
      text-align: start;
      width: 100%;
      padding: 8px;
      &:hover {
        border-radius: 8px;
        background-color: var(--gray-100);
      }
    }
  }
}