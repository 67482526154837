.custom-drawer-body {
  width: 400px;
}
.collective-agreementFlag {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 0px;
}
.collective-agreementFlag.true svg {
  color: #10b981;
  font-size: 20px;
  margin-right: 10px;
}
.collective-agreementFlag.false svg {
  color: #ef4444;
  font-size: 20px;
  margin-right: 10px;
}
