.page-login {
  display: block;
  background-color:#F2F3F5;
  min-height: 100vh;
}

.page-login > .bt-grid {
  min-height: 100vh;
}

body .app-login {
  width:300px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  body .app-login {
    width:100%;
  }

  .bt-grid .first-column {
    flex-basis: 100% !important;
    margin-top: 0;
  }

  .bt-grid .bt-row div.bt-column.second-column {
    display: none;
  }
}
