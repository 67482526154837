
.removeIcon {
  svg {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    border: 3px solid var(--white);
    border-radius: 50%;
    height: 26px;
    width: 26px;

    cursor: pointer;

    transition: fill 300ms;
    fill: var(--gray-500);

    &:hover {
      fill: var(--gray-700);
    }
  }

  &:hover svg {
    display: block;
  }
}

.cropper {
  border: 1px solid var(--gray-300);
}

.cropArea {
  color: rgba(17, 24, 39, 0.33) !important;
}