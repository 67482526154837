.signup {
  margin: 16px auto;
  width: 832px;
  color: var(--gray-700);
  font-size: 14px;

  h1 {
    color: var(--gray-900);
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  p {
    font-size: 14px;
  }
  b { 
    font-weight: 500;
    color: var(--gray-900);
  }
  .signupButton {
    display: flex;
    justify-content: center;
  }
  .bigNumbers {
    margin: 32px 0;

    .row {
      display: flex;
      justify-content: space-between;

      .number {
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
      }  
    }
  }
}