.page-projects-single {
  display: block;
  width: 100%;
}
.page-projects-single .app-project-overview-calc {
  width: 91%;
}
page-projects-start .bg {
  bottom: 0px;
}

.tab.selected {
  color: #1F2937;
  border-bottom: 2px solid #FF5C00;
}

.tab-container {
  display: flex;
}

.tabs {
  border-bottom: 1px solid #E5E7EB;
  font-weight: 500;
  margin-bottom: 28px;
  flex-grow: 1;
  font-size: var(--fontSize-14);
}

.tab {
  display: inline-block;
  margin-top: 8px;
  margin-right: 32px;
  padding-bottom: 16px;
  color: #6B7280;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tab:not(.selected):hover {
  color: #374151;
  border-bottom: 2px solid #D1D5DB;
}

.tab-actions>.bt-button {
  margin-left: 20px;
}

@media (max-width:1540px){
  .tab {
    margin-right: 1.2vw;
  }
  .tab-actions>.bt-button {
    margin-left: 0.6vw;
  }
}

