.app-label {
    color: #001659;
    font-size: 0.86em;
    text-transform: uppercase;
}
.app-label label {
    font-weight: 500;
}
.app-label .mandatory {
    margin-left: 3px;
    color: #ff5e15;
}
.app-label input {
    background-color: transparent;
    margin-top: 10px;
    display: block;
    border-bottom: 1px solid #e2e2ea;
    width: 100%;
    padding: 1.5em 0;
    color: var(--gray-700);
}
.app-label.v2 input{
    font-weight: bold;
    background-color: white !important;
    font-size: 3em;
    margin-top: 10px;
    display: block;
    border-bottom: 1px solid #e2e2ea;
    width: 100%;
}
.rdw-editor-main{
    background-color: white !important;
}