.app-add-supplier {
  
  display: block;
  width: 100%;
  max-width: 1188px;
}
.app-add-supplier input {
  letter-spacing: 0.02em;
}
.app-add-supplier .colum-left {
  width: 80%;
  background-color: white;
}
.app-add-supplier .colum-left .content-left {
  padding: 50px 70px;
}
.app-add-supplier .colum-left app-label {
  margin-top: 3.8em;
  display: block;
}
.app-add-supplier .colum-left app-label:first-child {
  margin-top: 0;
}
.app-add-supplier .colum-left bt-button {
  margin-top: 40px;
  float: right;
}
.app-add-supplier .colum-left .bt-button {
  margin-top: 40px;
  float: right;
}
.app-add-supplier .colum-right {
  flex: 0 1 44%;
  background-color: #001659;
}
.app-add-supplier .colum-right .content-right {
  padding: 50px 70px;
}
.app-add-supplier .colum-right .content-right .headline {
  margin-bottom: 5px;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: normal;
  display: block;
  color: white;
  letter-spacing: 0.1em;
}
.app-add-supplier .colum-right .content-right h2 {
  
  margin: 0;
  padding: 0;
  color: white;
  font-size: 3em;
  margin-bottom: 0.65em;
}
.app-add-supplier .colum-right .content-right p {
  color: white;
  margin: 0;
  margin-top: -10px;
  letter-spacing: 0.1em;
  line-height: 2.2em;
}
.switch-right {
  margin-top: 40px;
  display: flex;
  float: right;
  align-items: center;
}
.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}
.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}
/* .react-toggle--checked:hover(.react-toggle--disabled) .react-toggle-track {
  background-color: rgb(0, 0, 0) !important;
} */
.react-toggle-track {
  background-color: #000 !important;
}
.blackList-main-container .react-toggle {
  margin-left: 9px !important;
}
.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: none;
}
.img-warning {
  float: right;
  width: 46px;
  position: absolute;
  right: 15%;
  top: 5%;
}
.img-warning-hover:hover .img-msg{
  display: block;
}
.img-msg {
  display: none;
  width: 55px;
  float: right;
  position: absolute;
  top: 0%;
  right: 19%;
  cursor: pointer;
}
.img-msg-show {
  display: block;
  width: 45px;
  cursor: pointer;
  position: absolute;
  top: 30px;
  left: 70px;
}
input.addSup-orgNo::placeholder {
  color: #c2bbbb;
}


.inputcontainer {
  position: relative;
}

.addSup-orgNo {
  width: 100%;
  box-sizing: border-box;
}

.addSup-orgNo-icon-container {
  position: absolute;
  right: 70px;
  top: calc(50% - 110px);
}
.addSup-orgNo-loader {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}

@keyframes around {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.addSup-orgNo-loader::after, .addSup-orgNo-loader::before {
  content: "";
  background: white;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #333 #333 transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.addSup-orgNo-loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}

.addEditSupplier .bt-check.checked label {
  color: #001659 !important;
}
.editera-hr {
  border-top: 1px solid #E5E7EB;
  margin-bottom: 19px;
}

.frameworkAgreement { 
  display:flex; 
  flex-direction: column; 
  color: var(--gray-700);
  font-size: 14px;
  position:relative;
  flex-wrap: wrap;
  gap: 20px;
}
.frameworkAgreement>div { width: fit-content; max-width: 100%; }
.frameworkAgreement>div:first-child { width: unset;}
.frameworkAgreement { margin: 0; }
.frameworkAgreement dt { font-weight: 500; }
.frameworkAgreement dd { margin: 0; margin-top: 5px; }
.frameworkAgreement:hover .floating-action {
  transform: translateX(0%);
  opacity: 1;
}
.app-add-supplier .btn-attach .ar-button {
  padding: 7px 15px;
}
.app-add-supplier .uploaded-files bt-filetype a {
  margin-top: 0;
}
.app-add-supplier bt-filetype img {
  height: 16px;
  vertical-align: middle;
}
.app-add-supplier .app-chat-mail-batch .btn-attach-wrapper {
  margin-top: 0;
  vertical-align: top;
  margin-bottom: 16px;
}
label.ar-label span.headline {
  text-transform: none;
}

.refreshSynaButton span {
  margin: 0 !important;
  text-transform: none !important;
}
.refreshSynaButton {
  margin-top: 0 !important;
  border-radius: 6px !important;
}
.identityField {
  flex: 1;
}
.org-vatLabel input {
  border-bottom: none;
  width: auto;
  padding: 0;
  
}
label.org-vatLabel div {
  text-transform: none;
}

.addEditSupplierUploadFileButton .slot {
  margin-bottom: 0;
}

.supplierAdPlacement {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 16px;
}

.supplierAdPlacementDeletable {
  grid-template-columns: 1fr 1fr auto;
}

.supplierAdPlacementDeletable > :nth-child(3) {
  margin-bottom:  -22px;
}
.supplierAdPlacement:not(.supplierAdPlacementDeletable) > *,
.supplierAdPlacement > :nth-child(4),
.supplierAdPlacementDeletable > :nth-child(5) {
  grid-column: 1 / span 2;
}

.supplierAdPlacementDeletable + .supplierAdPlacementDeletable {
  padding-top: 16px;
  border-top: 1px solid var(--gray-200);
}

.supplierControls-addSupplierModal {
  background-color: var(--gray-50);
  border-radius: 6px;
  padding: 32px;
  font-size: 12px;
}

h3.inner-sec-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.supplierControls-addSupplierModal h3.inner-sec-title {
  color: #111827;
  font-weight: 500;
}

.featuredSupplierOptions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.featuredSupplierOptions > *:nth-child(n + 3) {
  grid-column: span 2;
}