.show-more-text-container {
  margin-bottom: 20px;
  

  .show-less {
    overflow-y: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
    min-height:7em;
  }
  .show-more {
    -webkit-line-clamp: unset;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }


  .btn {
    font-size: var(--fontSize-12);
    line-height: 16px;
    text-decoration-line: underline;
    color: var(--gray-700);
    margin-top: 11px;
    cursor: pointer;
    width: fit-content;
  }
}