.xIconWrapper {
  position: relative;
  & > * {
    height: 100%;
  }

  & > svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 11px;
    right: 11px;
    fill: var(--gray-700);
    cursor: pointer;
  }
}