.bt-grid {
  width: 100%;
  min-height: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;

  &.bt-contract {
    display: inline-flex;
    width: auto;
    height: auto;
    min-height: auto;

    div.bt-column {
      flex: 0 1 auto;
    }
  }

  &.bt-vcontract {
    display: inline-flex;
    height: auto;
    min-height: auto;
  }

  &.bt-debug {
    border: 1px solid red;

    .bt-row {
      border: 1px solid yellow;
    }

    .bt-column {
      border: 1px solid blue;
    }
  }

  @for $i from 1 through 20 {
    $value: $i * 5;
    .bt-w#{$value} {
      flex: 0;
      flex-basis: $value + 0%;
    }
  }

  .bt-row {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;

    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;

    &.bt-contract {
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .bt-column {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;

      &.bt-contract {
        flex: 0 1 auto; // Use this code also for .bt-row, bt-grid when contracting? This is for IE11
      }

      &.bt-center,
      .bt-center {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }

      &.bt-hcenter {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        align-self: flex-start;
      }

      &.bt-vcenter {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: flex-start;
      }

      &.bt-right {
        align-items: flex-end;
      }

      &.bt-overflow {
        overflow: auto;
      }
    }
  }
}
