.droppable-container {
  list-style: none;
  padding: 0;
}

.droppable-container li {
  padding-left: 8px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.droppable-container li:hover {
  background-color: var(--gray-50);
}

.droppable-container li:hover .draggable-icon svg,
.isDragging .draggable-icon svg {
  opacity: 1;
}

.droppable-container .dropdownContainer {
  display: flex;
  align-items: center;
  padding: 8px 24px 8px 0px;
}

.dropdownContainer .draggable {
  width: 45%;
  min-width: 350px;
}

.dropdownContainer .draggable input {
  height: 30px;
}

.MuiAutocomplete-paper li { /* Styles for price-field/demand autosuggest dropdown options */
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: var(--gray-700);
}

.dropdownContainer .accSelector__control {
  padding-top: 0px;
  padding-bottom: 0px;
}

.draggable-icon svg {
  opacity: 0;
  fill: var(--gray-500);
}

.dropdownContainer .remove-icon {
  fill: var(--gray-500);
}

.dropdownContainer .remove-icon svg {
  width: 14px;
}
.dropdownContainer .remove-icon {
  cursor: pointer;
  margin-left: auto;
  fill: var(--gray-500);
  width: 30px;
  height: 26px;
  background: #ffffff;
  border: 1px solid var(--gray-300);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
