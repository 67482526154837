.app-login {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-login .title {
  color: var(--gray-900);
  font-weight: var(--weight-600);
  font-size: var(--fontSize-18);
  margin-top: 90px;
  margin-bottom: 24px;
}
.app-login .btn-login-wrapper {
  float: right;
  vertical-align: bottom;
  align-self: flex-end;
}
.app-login .btn-login-wrapper .btn-login-wrapper {
  margin-top: 16px;
}
.app-login .small {
  font-size: 0.72em;
}
.app-login .input-break {
  margin-bottom: 16px;
}
.app-login bt-input {
  display: block;
}
.app-login bt-input input {
  min-width: 100%;
  font-size: 1em;
  padding: 10px 0;
  background-color: transparent;
  border-bottom: 1px solid #001659;
}
.app-login bt-input input::placeholder {
  margin: 0;
  position: relative;
}
.app-login .input-wrapper {
  margin-top: 64px;
  display: flex;
    flex-direction: column;
    width: 100%;
}
.app-login .forgot-password {
  margin-top: 16px;
  display: block;
  color: #001659;
}
.app-login .error {
  width: 100%;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  margin-top: 20px;
  
  color: #fe5150;
  font-size: 0.85em;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
}
.app-login .error.show {
  opacity: 1;
  transform: translateY(0px);
}



.app-login .input-wrapper input {
  margin-top: 5px;
}

.btn-login-wrapper .bt-button.btn-login {
  padding: 7px 15px 9px;
  border-radius: 5px;
}

.forgot-password-wrapper .forgot-password {
  margin-top: 0 !important;
  margin-bottom: 10px;
}

.app-login input[type="password"] {
  margin-bottom: 6px;
}
.app-login input::placeholder {
  color: var(--gray-500);
}

.app-add-supplier.app-create-project.custom-forgot-modal {
  max-width: 50% !important;
  margin: 0 auto;
}

.custom-forgot-modal .forgot-modal-title {
  font-weight: 600;
  margin-bottom: 15px;
}

.custom-forgot-modal 
.forgot-modal-description {
  margin-bottom: 20px;
}


.custom-forgot-modal 
.forgot-modal-form input {
  margin-top: 0;
  margin-bottom: 15px;
}



.custom-forgot-modal .forgot-modal-form  .footer-btn-container button {
  padding: 7px 15px 5px;
  font-size: 14px !important;
}


@media only screen and (max-width: 768px) {
  .app-login {
    margin-top: 5vh;
    margin-bottom: 5vh;
 }
  .app-login .input-wrapper {
    margin-top: 20px;
 }
  .app-login .logo {
    width: 120px;
 }
 .input-wrapper.custom-input-wrapper {
  width: 50%;
 }
}
