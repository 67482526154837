.container {
  border: 1px solid var(--gray-300);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 16px;
  border-radius: 6px;
  background-color: var(--white);
  gap: 16px;
  transition: height 300ms ease-in-out;

  .input {
    flex: 1;
    width: 100%;
    font-size: 16px;
    padding: 0 16px 0 16px;
    margin-top: 16px;
    background-color: transparent;
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 16px;      

    &::placeholder {
      color: var(--gray-400)
    }
  }

  .bottomContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 16px 16px 16px;
    gap: 16px;

    .fileContainer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 1;

      border-radius: 6px 6px 0 0;
      gap: 16px;
      transition: 300ms;
    }

    .sendButton {
      width: 160px;
      height: 50px;
  
      svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
  
        path {
          transition: stroke 300ms;
        }
      }
  
      &:hover {
        svg {
          path:last-of-type {
            stroke: var(--orange-500);
          }
        }
      }
    }
  }

  .fileUpload {
    letter-spacing: normal;
    
    svg {
      fill: var(--gray-700);
      transition: fill 300ms;
      width: 16px;
      height: 16px;
    }

    &:not(.fileUploadDisabled):hover svg {
      fill: var(--orange-500);
    }
  }
}