.confirmationDialog {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > span {
    font-size: 14px;
    color: var(--gray-700);
  }

  .confirmationItem {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    border-radius: 4px;
    cursor: default;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: var(--gray-900);
    }

    span {
      font-size: 14px;
      color: var(--gray-500);
    }
  }
}

.searchContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  margin-left: 16px;

  span {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    color: var(--gray-900);
  }
}