.emailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  padding: 8px;
  border: 1px solid var(--gray-300);

  .emailContent {
    background-color: var(--white);
    padding: 32px;
    max-width: 600px;
    box-shadow: 2px 2px 5px var(--gray-300);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    & > * {
      margin: 0;
    }
  }

  footer {
    text-align: center;
    font-size: 14px;
    margin-top: 16px;
  }
}