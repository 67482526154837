.newsFeedCard {
  width: 250px;
  height: fit-content;
  background-color: var(--white);
  box-shadow: 1px 2px 8px var(--gray-200);
  padding-bottom: 16px;

  border-radius: 8px;
  color: var(--gray-700);

  text-decoration: none;

  display: flex;
  flex-direction: column;

  gap: 8px;

  &[href] {
    cursor: pointer;
  }

  &:hover {
    box-shadow: 1px 2px 8px var(--gray-300);
  }

  .detailsContainer {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px 0 16px;

    span {
      font-size: 12px;
      align-self: flex-end;
    }

    div {
      display: flex;
      gap: 8px;

      span {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  p {
    font-size: 16px;
    font-weight: 600;
    margin: 0 16px !important;
    margin-bottom: 0;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
  }

  img {
    border-radius: 8px 8px 0 0;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 8px;
  }
}