.addRecipient {
  border-radius: 6px;

  > div:first-of-type{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top:1px solid var(--gray-200);
  }
  
  > div:last-of-type{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom:1px solid var(--gray-200);
  }

  .rowContainer {
    display: flex;
    border-right: 1px solid var(--gray-200);
    border-left:1px solid var(--gray-200);
    border-top:1px solid var(--gray-200);
    padding: 16px 26px 26px 17px;
    cursor: pointer;
  }
  div.selected {
    border: 1px solid var(--indigo-200);
    background-color: var(--indigo-50);
  }
  .selected + .rowContainer {
    border-top: 0px;
  }
  div.noBorder {
    border-bottom: 0px;
  }
  .radioIcon {
    margin-right: 15px;
    .sharingModal {
      padding: 0px;
      margin-left: 0px;
    }
  }
  .radioIcon+div {
    flex-grow: 1;
  }
  .icon {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    background-color: var(--white);
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
  }
  .checkedIcon {
    background-color: var(--indigo-600);
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));
    box-shadow: none;
    &:before {
      display: block;
      width: 16px;
      height: 16px;
      background-image: radial-gradient(var(--white), var(--white) 28%, transparent 32%);
      content: '';
    }
    input:hover ~ & {
      background-color: var(--indigo-600);
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    gap: 24px;
  }
  .column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}