.app-project-single-purchase {
  font-family: var(--primaryFont);
  color: var(--gray-700);
  font-size: 14px;
  font-weight: 500;
}
.app-project-single-purchase:hover bt-popover.supplier svg {
  opacity: 1;
}
.app-project-single-purchase.status-ongoing bt-progress-bar {
  background-color: #5fc5fc;
}
.app-project-single-purchase.status-idle bt-progress-bar {
  background-color: #989898;
}
.app-project-single-purchase.status-warning bt-progress-bar {
  background-color: #eeb52f;
}
.app-project-single-purchase.status-success bt-progress-bar {
  background-color: #6dd401;
}
.app-project-single-purchase.status-success:hover table.suppliers tr.active svg {
  opacity: 1;
}
.app-project-single-purchase.status-success table.suppliers tbody tr.active td {
  pointer-events: all;
  opacity: 1;
}
.app-project-single-purchase.status-success table.suppliers tbody td {
  opacity: 0.3;
  pointer-events: none;
}
.app-project-single-purchase.status-success table.suppliers tbody td svg {
  opacity: 0;
}
.app-project-single-purchase.status-danger bt-progress-bar {
  background-color: #fe5150;
}
.date-end {
  color: var(--gray-600);
  font-size: 8px;
  line-height: 10px;
  
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.date-end span {
  font-weight: 400;
  margin-bottom: -10px;
  font-size: 12px;
}
.header .date-end {
  font-size: 12px;
  line-height: 14px;
}
.header .date-end span {
  font-size: 22px;
}
.app-project-single-purchase .column-price {
  cursor: pointer;
  transition-property: color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.app-project-single-purchase .column-price:hover {
  color: #ff5e15;
}

.lock-disable {
  opacity: 1;
}
.app-project-single-purchase bt-progress-bar {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  top: -2px;
  left: 0px;
  position: absolute;
}
.app-project-single-purchase .suppliers {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  width: 100%;
  border-collapse: collapse;
  font-weight: 500;
}
.app-project-single-purchase .suppliers thead th {
  font-weight: inherit;
  text-align: left;
  border-bottom: 1px solid #e2e2ea;
}
.app-project-single-purchase .suppliers thead th:last-child {
  width: 1%;
  text-align: right;
}
.app-project-single-purchase .suppliers tbody {
  color: #49494b;
}
.app-project-single-purchase .suppliers tbody tr td {
  padding: 0;
  white-space: nowrap;
  font-size: 1.2em;
}
.app-project-single-purchase bt-popover.supplier svg {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  opacity: 0;
  margin-left: 8px;
}
.app-project-single-purchase bt-popover .last {
  right: auto;
}
.app-project-single-purchase bt-popover .bt-button {
  padding: 10px;
  border: 0;
}
.app-project-single-purchase bt-popover.supplier .bt-button button {
  border: 0;
}
.app-project-single-purchase bt-popover.supplier .remove-winner {
  background-color: white;
  color: red;
}
.app-project-single-purchase .header .counter {
  margin-top: -7px;
}

.calendar-box:hover .text-hide-cal {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.calendar-box {
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.text-hide-cal {
  display: none;
}

.calendar-box:hover .calendar-icon,
.calendar-box .calendar-icon.selected {
  fill: #ffa600;
}
.calendar-box svg.selected {
  height: 28px;
  width: 28px;
}

.excel-dropdown {
  margin-top: 20px;
  float: right;
}
.ov-toggle-box.excel-dropdown {
  right: 0;
  left: unset;
  top: 0;
}
.ov-toggle-box.excel-dropdown .dropdownLi {
  padding: 8px 26px;
  line-height: 16px;
  cursor: pointer;
  color: black;
}
.text-hide-cal {
  margin-top: 3px;
}
/*salman Css*/
.excel-icon {
  width: 20px;
  margin-right: 5px;
}
.width-addjust {
  max-width: fit-content;
  margin-right: 10px
}
.excel-dropdown svg {
  margin-bottom: 5px;
  display: inherit;
}
.excel-dropdown input {
  display: none;
}
.rdw-editor-main{
  padding-left: 10px;
  padding-right: 10px;
}
.app-chat-mail-batch .message .message-wrapper .emailTextBox {
  padding: 5px;
  padding-right: 10px;
}
.center-drop-down img {
  width: 20px;
}
.excel-dropdown.center-drop-down {
  width: auto;
  padding-left: 20px;
  margin-top: 10px;
}
.center-drop-down .js-version-name {
  margin-left: 5px; 
}
.center-drop-down svg {
   margin-bottom: 0;
}
.center-drop-down div {
  display: flex;
  align-items: center;
}
.center-drop-down .ov-toggle-box.excel-dropdown {
  right: -150px;
  left: 0;
  margin: 0 auto;
  min-width: 60px;
  width: 60px;
}
.center-drop-down .style--margin-left {
  display: flex;
  align-items: center;
}
.lft-box-price {
  flex: initial;
}
.item-pro-over-item .btp-content-cover {
  min-height: 28px;
}
.item-pro-over-item .sl-version-selector a {
  min-height: 28px;
}
.lock-icon-class > svg {
  height: 18px;
  margin-bottom: -2px;
  margin-left: 12px;
  margin-right: -3px;
  fill: #49494b;
}
.bid-card-blackList-icon {
  width: 18px !important;
}

.oldVersion {
  visibility: hidden;
}
.tips {
  width: fit-content;
}
.tips p {
  font-size: 1em;
  font-weight: 500;
  text-decoration: none;
  color: var(--gray-700);
}
.tips p:hover {
  text-decoration: underline;
}

.navLink {
  text-decoration: none;
}

.overviewItemSuppliersDiv {
  display: flex;
}

.supplierRowHeader {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  height: 30px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-700);
}
.supplierRowActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding: 5px 0;
}
.supplierRowActions > * {
  display: flex;
}
.supplierRowActions img {
  cursor: pointer;
  height: 18px;
}

.suppliersRightPart {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  max-width: 50vw;
}
.supplierInfoRow {
  align-items: center;
  padding-right: 15px;
  gap: 20px;
}
.supplierPriceRow {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.supplierInfoRow,
.supplierPriceRow {
  position: relative;
  display: flex;
  height: 90px;
  border-bottom: 2px solid var(--gray-200);

  opacity: 1;
  transition: opacity 300ms;
}
.supplierInfoRow.notSelectedSupplier .supplierRowHeader,
.supplierPriceRow.notSelectedSupplier {
  opacity: 0.4;
}
.supplierInfoRow.notSelectedSupplier {
  border-bottom-color: rgba(229, 231, 235, .4);
}
.supplierRowIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.supplierPriceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  min-width: 6.5em;
}
.supplierPriceContainer span {
  white-space: nowrap;
  font-size: 12px;
}
.supplierPriceContainer span:last-child {
  font-weight: 400;
}
.mailIconContainer {
  position: relative;
  display: flex;
}
.mailIconContainer div:first-child {
  position: absolute;
  top: -30%;
  left: 40%;
  border-radius: 50%;
  background-color: var(--red-500);
  color: var(--white);
  display: grid;
  place-items: center;
  height: 100%;
  aspect-ratio: 1;
  font-weight: 600;
  font-size: 12px;
  transform: scale(.7);
}



.bitcard-wizard-changer-select {
  margin-top: 10px;
}

.teamBuilderContainer {
  display: flex;
  width: fit-content;
  padding-right: 5px;
  white-space: nowrap;
}
.teamBuilderContainer bt-avatar {
  width: 35px;
  height: 35px;
}
.teamBuilderContainer > div:first-child :not(:first-child) {
  margin-left: -6px;
}
.teamBuilderContainer > div:first-child {
  cursor: pointer;
}

.bidcard-deleteArea {
  display: grid;
  place-items: center;

  position: absolute;
  top: 0;
  left: -32px;

  width: 32px;

  cursor: pointer;
  overflow: hidden;

  opacity: .4;
  transition: opacity 300ms;
}
.bidcard-deleteArea:hover {
  opacity: .9 !important;
}
p.supplier-name span {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
.teamBuilderAddDiv {
  position: relative;

  width: 35px;
  height: 35px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23D1D5DBFF' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='3' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 100px;

  margin-left: 5px;

  cursor: pointer;
}
.teamBuilderAddDiv.noUsersSelected {
  margin-left: 0;
}
.teamBuilderAddDiv svg:first-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.teamBuilderAddDiv svg:last-child {
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  padding: 4px;
  border-radius: 50%;
  background-color: var(--gray-300);
}
.teamBuilderAddDiv svg:last-child {
  transition: background-color 300ms;
}
.teamBuilderAddDiv:hover svg:last-child {
  background-color: var(--gray-400) !important;
}

.teamBuilderModalContainer {
  max-height: 50vh;
  overflow-y: auto;
  margin-bottom: 10px;
  display: grid;
  gap: 5px;
  padding-right: 10px;
}
.teamBuilderModalContainer div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  /* padding: 5px; */
  border-radius: 6px;
}
.teamBuilderModalContainer div bt-avatar {
  width: 35px;
  height: 35px;
}
.teamBuilderModalContainer div > div > svg {
  cursor: pointer;
  visibility: hidden;
}
.teamBuilderModalContainer div:hover {
  background-color: var(--gray-50);
}
.teamBuilderModalContainer div:hover > div > svg {
  visibility: visible;
}
.firstRowBidCardControls {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
.firstRowBidCardControls .titleWrapperContainer {
  flex: 1;
}
.firstRowBidCardControls .titleWrapperContainer:first-child .bt-button {
  width: 100%;
}
.budgetFieldOnSinglePurchasePage {
  background-color: var(--gray-100);
  border-color: var(--gray-100);
  height: 32px;
}
.budgetFieldOnSinglePurchasePage input {
  height: 32px !important;
}
.budgetFieldOnSinglePurchasePage input::placeholder {
  font-weight: 500;
  color: var(--gray-100);
  opacity: .3;
}
.supplierViewControls {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 0;
}
.supplierViewControls > span {
  font-weight: 600;
  color: var(--gray-600);
}
.supplierViewControls > span:hover {
  cursor: pointer;
  text-decoration: underline;
}
.secondRowBidCardControls {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 5px;
}

.selectedContractRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectedContractRow img {
  height: 20px;
}

.selectedContractStatuses {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.selectedContractLoading {
  display: flex;
  justify-content: center;
  margin: 1em;
}

.signatoryRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signatoryName {
  font-weight: 400;
  font-size: 14px;
  color: var(--gray-900);
  min-width: 40%; 
}

.signatoryStatus {
  font-weight: 400;
  font-size: 12px;
  color: var(--gray-400);
}

.signatoryButtonWrapper {
  min-width: 40%;
  text-align: right;
}

.selectedContractMessage {
  font-weight: 400;
}

.selectedContractMessage p {
  font-weight: 500;
  margin-top: 0;
}

.selectedContractControls {
  position: relative;
}

.selectedContractControls h3.inner-sec-title {
  color: #111827;
  font-weight: 500;
}

.selectedContractControls .supplierControlsElementToHide {
  display: none;
}

.supplierControlsHTML {
  background-color: var(--gray-50);
  border-radius: 6px;
  font-size: 12px;

  max-height: 0;
  opacity: 0;
  overflow: hidden;

  transition: max-height 300ms ease-out, opacity 300ms ease-in-out, margin-top 300ms ease-in-out, padding 300ms ease-in-out;
}
.supplierControlsHTMLExpanded {
  margin-top: 16px;
  padding: 12px;
  padding-bottom: 0;
  max-height: 700px;
  opacity: 1;
}

.bidIntentTooltip div p:first-child {
  display: inline;
  white-space: nowrap;
}
.bidIntentTooltip div p:last-child {
  margin: 5px 0;
  min-width: 250px;
}

@media (max-width:1440px){
  .acsp-bid p.supplier-name {
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  
  table.suppliers th:nth-child(3) .bitcard-budget-pricetype-row {
      float: right;
      padding-right: 2px;
  }
  
  }

  table.suppliers td:nth-child(2) .acsp-bid {
      min-width: 30px;
  }
  .acsp-bid .supplier-main .lock-icon-class>svg {
    margin-right: -6px;
}
.selected-team-avatar {
  max-width: unset !important;
}
.teamBuilderModalContainer .tbSubContainer {
  padding: 5px 10px;
}
.hiddenSupplierLink {
  position: relative;
}
.hiddenSupplierLink .notificationBadge{
    position: absolute;
    top: -10px;
    right: -20px;
    transform: scale(0.8);
}
.dateExtensionModal {
  width: 800px;
}
.dateExtensionModal h2 {
  margin: 35px 0;
}
.dateExtensionModal p {
  margin: 3px 0;
}
.dateExtensionModalDateContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dateExtensionModalFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: var(--gray-700);
}
.dateExtensionModalFooter p {
  margin: 5px;
}
.dateExtensionModalFooter a {
  color: var(--gray-700);
}