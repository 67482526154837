.container {
  display: flex;
  width: 100%;
  gap:16px;
  min-height: 80vh;

  .newsColumn {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.grid {
  display: grid;
  width: 100%;
  grid-template-areas:
    "a b c"
    "d e e"
    "f g h"
    "i j j";

  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: 116px minmax(390px, max-content) 116px minmax(390px, max-content);

  gap: 16px;

  & > * {
    min-width: 1fr;
    &:nth-child(1) {
      grid-area: a;
    }
    &:nth-child(2) {
      grid-area: b;
    }
    &:nth-child(3) {
      grid-area: c;
    }
    &:nth-child(4) {
      grid-area: d;
    }
    &:nth-child(5) {
      grid-area: e;
    }
    &:nth-child(6) {
      grid-area: f;
    }
    &:nth-child(7) {
      grid-area: g;
    }
    &:nth-child(8) {
      grid-area: h;
    }
    &:nth-child(9) {
      grid-area: i;
    }
    &:nth-child(10) {
      grid-area: j;
    }
  }
}