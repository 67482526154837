.container {
  background-color: white;
  .buttons {
    display: flex;
    gap: 16px;
    padding: 0 0 16px 0;
    height: 40px;
    align-items: center;
  }

  .dragAndDropText {
    font-size: 12px;
    color: var(--gray-500);
    display: block;
    text-align: center;

    a {
      color: var(--gray-900);
      font-weight: 500;
      cursor: pointer;
    }
  }

  .pathContainer {
    display: flex;
    gap: 4px;
    align-items: center;
    padding-left: 3px;
    margin-bottom: 6px;

    & > :first-child {
      display: flex;
      padding: 6px;
      border-radius: 2px;

      svg {
        color: var(--gray-500);
      }

      &:hover {
        cursor: pointer;
        background-color: var(--gray-100);

        svg {
          color: var(--gray-700);
        }
      }
    }

    &.isRoot {
      & > :first-child {
        background-color: transparent !important;
        cursor: default;
        svg {
          color: var(--gray-300);
        }
      }

      & > span {
        &:last-of-type {
          cursor: default;
        }
      }
    }

    .path {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 4px;
      font-size: 12px;
      font-weight: 500;
      color: var(--gray-500);

      & > span {
        padding: 4px 6px;
        border-radius: 2px;

        &:hover {
          cursor: pointer;
          background-color: var(--gray-100);
          color: var(--gray-700);
        }

        &:last-of-type {
          background-color: transparent !important;
          color: var(--gray-700);
          cursor: default;
        }
      }
    }
  }

  .table {
    width: 100%;
    min-height: 128px;
    thead tr {
      background-color: var(--gray-50);
    }

    th {
      text-align: start;
      color: var(--gray-900);
      font-size: 12px;
      font-weight: 500;

      .tableHeader {
        display: flex;
        gap: 8px;
        align-items: center;
        cursor: pointer;

        svg {
          opacity: 0;

          &.asc {
            opacity: 1;
            transform: rotate(180deg);
          }
          &.desc {
            opacity: 1;
          }
        }
      }
    }

    tbody:not(.dragging) tr {
      border-left: 2px solid transparent;
      &:hover {
        background-color: var(--light-blue-100);
        border-color: var(--light-blue-100);
      }
    }
    tbody tr:hover .checkbox {
      opacity: 1 !important;
    }

    tbody tr.filler:hover {
      background-color: unset;
      border: none;
    }

    tbody {
      min-height: 96px;
    }

    tbody.dragging {
      position: relative;
      border: 2px dashed var(--gray-300);
    }

    tbody.dragging::after {
      content: "Släpp filer här";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; 
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;      
      color: var(--gray-500);
    }

    tbody.dragging.dragOver {
      border-color: var(--gray-500);
    }
    tbody.dragging.dragOver::after {
      color: var(--gray-700);
    }


    td {
      color: var(--gray-500);
      font-size: 12px;
      height: 32px;

      &:nth-child(n+3) {
        width: 250px;
      }
    }

    :not(.dragging) .selected {
      background-color: var(--light-blue-50);
      border-left: 2px solid var(--light-blue-50);
    }
    :not(.dragging) .highlighted {
      border-left: 2px solid var(--light-blue-200);

      &:not(.selected) {
        border-color: var(--light-blue-100);
      }
      &:hover {
        border-color: var(--light-blue-200);
      }
    }

    .fileNameColumn {
      color: var(--gray-800);
      .file {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div {
          display: flex;
          align-items: center;

          .fileIcon {
            margin-left: 4px;
            margin-right: 10px;
          }

          &:last-child {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.fileIconContainer {
  position: relative;
  height: 20px;
  width: 16.25px;
  & > svg {
    height: 20px;
    width: 16.25px;
    border-radius: 1.25px;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 12px;
    height: 12px;
  }
}

tbody tr:not(.disabledRow) .fileName:hover {
  text-decoration: underline;
  cursor: pointer;
}