.app-chat-header-clients stencil-route-link {
  -webkit-animation: 0.7s ease 0s normal forwards 1 fadein;
  animation: 0.7s ease 0s normal forwards 1 fadein;
  opacity: 0;
}

.app-chat-person {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  cursor: pointer;
}
.app-chat-person.has-conversation bt-avatar {
  overflow: hidden;
  opacity: 0;
  width: 1px !important;
}
.app-chat-person bt-avatar {
  font-size: 1.3em;
}
.app-chat-person bt-avatar span {
  margin-top: 1px;
}
.app-chat-person .wrapper {
  margin-top: 10px;
  margin-left: 10px;
}
.app-chat-person .wrapper .head {
  font-size: 0.8em;
  color: #6A6E77;
}
.app-chat-person .wrapper .body {
  line-height: 1em;
}
.app-chat-person .wrapper .body svg {
  vertical-align: bottom;
}
.app-chat-person .wrapper .foot {
  color: #9399a7;
  font-size: 0.7em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.app-chat-person.small bt-avatar {
  font-size: 0.9em;
  width: 30px;
  height: 30px;
}
.app-chat-person.small bt-avatar span {
  margin-top: 2px;
}
.app-chat-person.small .wrapper {
  margin-top: 0px;
}
.app-chat-person.small .wrapper .head {
  font-size: 0.7em;
}
.app-chat-person.small .wrapper .body {
  font-size: 0.8em;
}