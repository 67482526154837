.orContainer {
  border-top: 1px solid var(--gray-300);
  margin-top: 2em;
  margin-bottom: 1em;
  color: var(--gray-500);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  
  span {
    margin-top: -0.5em;
    text-align: center; 
    background-color: white;
    padding: 0 1em 1.5em 1em;
  }

}