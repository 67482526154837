.organization-switcher {
  float: right;
  z-index: 50;
  position: relative;
  /* display: flex; */
  flex-direction: column;
}
.sub-organization-switcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 20px 0px 0px;
}

.user--message .user-welcome {
  display: block;
  font-size: 14px;
  color: #9399a7;
}

.user--message .user-actop {
  font-size: 14px;
  color: #ff5e15;
  cursor: pointer;
  font-weight: 500;
}
.user--message .user-name {
  color: #001659;
}

.org-switcher-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.org-switcher-avatar {
  margin-right: 10px !important;
}

.avatar-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 16px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  cursor: pointer;
  width: fit-content;
  height: 40px;
  justify-content: space-between;
}
.notification-icon-wrapper {
  margin-right: 10px;
  cursor: pointer;
}
span.instance-name {
  font-size: 14px;
  margin-bottom: 4px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.avatar-dropdown-options {
  z-index: 1000;
  display: none !important;
  flex-direction: column;
  cursor: pointer;
}
.avatar-dropdown-options.show {
  display: flex !important;
  z-index: 12;
}
.sub-avatar-dropdown {
  display: flex;
  align-items: center;
}
.avatar-dropdown-option.main .name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #111827;
}
.avatar-dropdown-option.main .email {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
}
.avatar-dropdown-options{
  overflow: auto;
    max-height: 80vh;
}
ul#org-dropdown.avatar-dropdown-options li {
  padding: 10px;
  display: block;
  margin-left: 0;
}
ul#org-dropdown.avatar-dropdown-options li:hover:not(.selected) {
  background-color: var(--gray-50);
}
ul#org-dropdown ul li.avatar-dropdown-option.selected {
  padding-right: 25px;
  position: relative;
}

.badge {
  font-size: 10px;
  text-transform: uppercase;
  line-height: 16px;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: 12px;
  margin-right: 8px;
  letter-spacing: 0.5px;
}

.badge.sales {
  background-color: var(--purple-100);
  color: var(--purple-800);
}

.badge.purchasing {
  background-color: var(--light-blue-100);
  color: var(--light-blue-800);
}

.activeProductSelectorOrganizationList {
  width: 200px;
}
