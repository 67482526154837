.simpleModalContainer {
  align-self: center;
  justify-self: center;
  min-width: 550px;
  background-color: var(--white);
  padding: 25px;
  padding-right: 5px;

  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 6px;

  position: relative;
}
.simpleModalContent{
  padding: 25px 25px 3px 5px;
}
.simpleModalTitle {
  color: var(--gray-900);
  font-weight: 600;
  font-size: 19px;
  margin-top: 0;
}
.simpleModalButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 25px;
}

.simpleModalChildren {
  max-height: 50vh;
  overflow-y: auto;
}

.simpleModalCloseButton {
  position: absolute;
  top: 20px;
  right: 20px;

  cursor: pointer;
}
