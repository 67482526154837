.app-chat-message {
  display: block;
  
  color: #44444f;
  background-color: #fafafb;
}
.app-chat-message.new-message.person-client .overflow > .wrapper > .head::before {
  opacity: 1;
}
.app-chat-message .overflow {
  overflow: hidden;
}
.app-chat-message .overflow > .wrapper {
  border-top: 1px solid #e2e2ea;
  padding: 30px;
}
.app-chat-message .overflow > .wrapper > .head {
  position: relative;
  margin-bottom: 30px;
}
.app-chat-message .overflow > .wrapper > .head::before {
  transition-property: all;
  transition-duration: 0.5s;
  transition-delay: 1.1s;
  transition-timing-function: ease-out;
  content: " ";
  display: inline-block;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: #ff5e15;
  position: absolute;
  left: -10px;
  top: 0;
  opacity: 0;
}
.app-chat-message .overflow > .wrapper > .head .info {
  margin-top: 10px;
  float: right;
}
.app-chat-message .overflow > .wrapper > .head .info .readStatus .checkmark {
  font-family: initial;
  fill: #c9c9c9;
  width: 16px;
  height: 14px;
  margin-right: 6px;
  top: 1px;
  position: relative;
  display: inline-block;
}
.app-chat-message .overflow > .wrapper > .head .info .readStatus.is-read .checkmark {
  fill: #78e100;
}
.app-chat-message .overflow > .wrapper > .head .info .date {
  color: #a5a5a5;
}
.app-chat-message.message-new .overflow {
  display: none;
}
.app-chat-message:first-child .overflow > .wrapper {
  border-top: 0;
}
.app-chat-message:last-child {
  margin-bottom: 0;
}
.app-chat-message .uploaded-files bt-filetype.small a {
  font-size: 0.85em;
}
.app-chat-message .uploaded-files bt-filetype.small a img {
  width: 25px;
}
.app-chat-message .uploaded-files bt-filetype.small a .description {
  margin-top: 3px;
}
.app-chat-message .uploaded-files bt-filetype a {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  color: inherit;
  border: 1px solid #f1f1f5;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  margin-top: 20px;
  margin-right: 10px;
}
.app-chat-message .uploaded-files bt-filetype a[href]:hover {
  color: #ff5e15;
}
.app-chat-message .readMessage svg {
  color: #6a6e77;
}
