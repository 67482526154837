.page-projects {
  display: block;
  min-height: 100%;
  min-width: 100%;
}
.page-projects .route-chat {
  height: 100%;
}
.page-projects .flex-router,
.page-projects .flex-router stencil-route-switch,
.page-projects .flex-router stencil-route {
  display: flex;
  height: 100%;
  width: 100%;
}
.page-projects .industry-types > li {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.page-projects .link-active > .industry-types > li {
  opacity: 0.2;
}
.page-projects .link-active > .industry-types > li:hover {
  opacity: 1;
}
.page-projects
  .link-active
  > .industry-types
  > li:hover
  bt-accordion-head
  bt-button {
  color: #001659;
}
.page-projects .industry-types > li.link-active {
  opacity: 1;
}
.page-projects .column-content {
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.page-projects .menu-top {
  justify-content: flex-end;
}
.page-projects .menu-top ul,
.page-projects .menu-top li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.page-projects .menu-top ul,
.page-projects .menu-top li {
  display: inline-block;
}
.page-projects .menu-top bt-button {
  
  text-align: left;
  color: black;
  font-weight: 500;
  padding: 20px 30px;
  background-color: transparent;
  transition-duration: 0.15s;
  border-radius: 0;
}
.page-projects .menu-top bt-button:hover {
  box-shadow: none;
  color: #ff5e15;
}
.page-projects .menu-top bt-button:active {
  background-color: transparent;
  box-shadow: none;
}
.page-projects .menu-top .link-active bt-button {
  color: #ff5e15;
}
.page-projects .menu-left {
  background-color: white;
  border-right: 1px solid #e2e2ea;
  display: inline-block;
  /* height: 100%; */
  min-height: 100%;
}
.page-projects .menu-left a {
  text-decoration: none;
  text-transform: none;
}
.page-projects .menu-left hr {
  margin: 0;
  border: none;
  border-top: 1px solid #f1f1f5;
}
.page-projects .menu-left ul,
.page-projects .menu-left li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.page-projects .menu-left bt-slider,
.page-projects .menu-left .bt-slider {
  opacity: 0;
}
.page-projects .menu-left bt-slider.animate,
.page-projects .menu-left .bt-slider.animate {
  transition: opacity 0.2s ease-out, width 0.4s ease-out;
  opacity: 1;
}
.page-projects .menu-left .logo-wrapper {
  padding: 11px 40px;
  border-bottom: 1px solid #e2e2ea;
  height: 55px;
}

.page-projects .menu-left bt-button.add-button {
  white-space: nowrap;
  color: #001659;
}
.page-projects .menu-left bt-button.add-button svg g {
  transition: all 0.2s ease-out;
}
.page-projects .menu-left bt-button.add-button:hover {
  background-color: #ff5e15;
  color: white;
}
.page-projects .menu-left bt-button.add-button:hover svg g {
  fill: white;
}
.page-projects .menu-left bt-button {
  display: block;
  border-radius: 0;
  text-align: left;
  color: #ff5e15;
  font-weight: 600;
  padding: 20px 25px;
  background-color: transparent;
}
.page-projects .menu-left bt-button:hover {
  box-shadow: none;
}
.page-projects .menu-left bt-button:active {
  box-shadow: none;
}
.page-projects .menu-left bt-button svg {
  margin-right: 15px;
  position: relative;
  top: 1px;
}
.page-projects .menu-left bt-button span {
  text-align: left;
}
.page-projects .menu-left ul.projects {
  margin-top: 20px;
}
.page-projects .menu-left ul bt-button {
  white-space: nowrap;
  color: #001659;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  padding: 10px 25px;
}
.page-projects .menu-left ul bt-button:hover {
  box-shadow: none;
  color: #ff5e15;
}
.page-projects .menu-left ul bt-button:active {
  box-shadow: none;
}
.page-projects .menu-left ul bt-button app-indicator {
  position: relative;
  top: -1px;
  margin-left: 6px;
}
.page-projects .menu-left ul a.link-active bt-button {
  color: #001659;
}
.page-projects .menu-left ul a.link-active bt-button span {
  border-bottom: 1px solid #ff5e15;
}
.page-projects .menu-left bt-accordion-body-content a.link-active bt-button {
  color: #ff5e15;
}
.page-projects
  .menu-left
  bt-accordion-body-content
  a.link-active
  bt-button
  span {
  border-bottom: none;
}
.page-projects .menu-left .project-name {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #001659;
  
  font-size: 15px !important;
}
.page-projects .menu-left .project-name svg {
  transition: all 0.2s ease-in-out;
}
.page-projects .menu-left .project-name:hover svg {
  transform: translateX(-8px);
}
.page-projects .menu-left .project-name span {
  white-space: nowrap;
  display: inline-block;
  margin-top: 3px;
}
.page-projects .menu-left .add-button {
  display: block;
  font-size: 0.9em;
  padding-top: 20px;
  padding-bottom: 20px;
}
.page-projects .menu-left .suppliers ul {
  border: 1px solid #e2e2ea;
  border-right: 0;
  -webkit-box-shadow: inset 0px 0px 20px -2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0px 0px 20px -2px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px 0px 20px -2px rgba(0, 0, 0, 0.15);
}
.page-projects .menu-left .suppliers ul bt-button {
  font-size: 12px;
  padding: 7px inherit;
}
.page-projects .menu-left .suppliers ul li.supplier:first-child {
  margin-top: 10px;
}

.page-projects .menu-left .suppliers .supplier button::before {
  display: none;
}

.menu-left .bt-slider .ssa-action {
  white-space: nowrap;
  color: #001659;
  padding-top: 15px;
  padding-bottom: 15px;
}
.menu-left .bt-slider .ssa-action.supplier-empty {
  margin-top: 0;
}
.menu-left .bt-slider .ssa-action span {
  font-weight: 500;
  font-size: 12px;
}
.menu-left .bt-slider .ssa-action svg {
  fill: #001659;
}
.menu-left .bt-slider .ssa-action:hover {
  background-color: #ff5e15;
  color: white !important;
}
.menu-left .bt-slider .ssa-action:hover svg {
  fill: white !important;
}
.menu-left .bt-slider .ssa-action {
  margin-top: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  white-space: nowrap;
}
.menu-left .bt-slider .ssa-action .icon {
  font-size: 1.2em;
  transition: all 0.2s ease-out;
  color: #001659;
}
.menu-left .bt-slider .ssa-action svg {
  width: 14px;
  height: 14px;
  position: relative;
  top: 1px;
  margin-right: 13px;
}
.menu-left .bt-slider .ssa-action svg {
  fill: #001659;
}
.menu-left .bt-slider .ssa-delete {
  color: #ff0000;
}
.menu-left .bt-slider .ssa-delete:hover {
  background-color: #ff0000;
  color: #fff !important;
}
.menu-left .bt-slider .ssa-delete svg {
  fill: #ff0000;
}
.menu-left .bt-slider .ssa-delete:hover svg {
  fill: #ffffff;
}
.menu-left bt-button button {
  font-weight: 500;
}
.page-projects .menu-left .chat-colleagues {
  margin-top: 20px;
  white-space: nowrap;
}

.right--style,
.left--style {
  position: relative;
  margin: 20px;
  float: left;
  width: calc(50% - 100px);
}
.right-box--style,
.left-box--style {
  padding-left: 100px;
}

.left--style {
  height: 100%;
  border-right: 1px dotted #2c2c2c;
}
.project-content--style {
  background-color: lightgrey;
}
.logout-top {
  position: relative;
  float: right;
}

.colleagues-role-header {
  border: none !important;
  color: #ff6722;
  -webkit-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
  padding: 10px 25px;
  font-weight: 600;
}
.chat-colleagues .slot span {
  height: 18px;
}

.color_orange {
  color: #ff5e15;
}

.area-one {
  background-color: #ff5e15 !important;
  color: #fff !important;
}
.area-two {
  background-color: #203864 !important;
  color: #fff !important;
}
.area-three {
  background-color: #333e50 !important;
  color: #fff !important;
}
.activeStep {
  pointer-events: all;
  opacity: 1;
}
.inactiveStep {
  opacity: 0.3;
  pointer-events: none;
}
li.supplier {
  position: relative;
}
.project-remove-supplier {
  position: absolute;
  right: 10px;
  top: 5px;
}
.no-bt-slider {
  width: 210px !important;
}
.newCategoryContainer {
  display: grid;
  gap: 15px;
}
.newCategoryContainer > div p {
  margin: 5px 0;
}

.logoWrapper {
  display: grid;
  place-items: center;
  width: 211px;
  height: 101%;
  border-right: 1px solid var(--gray-300);
}
.scrollable-content {
  min-height: 100vh;
  height: 100%;
  margin-left: 256px
}