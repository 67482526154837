.rippler {
  --size: 18px;

  @keyframes ldsripple {
    0% {
      top: calc(var(--size) / 2);
      left: calc(var(--size) / 2);
      width: 0;
      height: 0;
      opacity: 0;
    }

    4.9% {
      top: calc(var(--size) / 2);
      left: calc(var(--size) / 2);
      width: 0;
      height: 0;
      opacity: 0;
    }

    5% {
      top: calc(var(--size) / 2);
      left: calc(var(--size) / 2);
      width: 0;
      height: 0;
      opacity: 1;
    }

    100% {
      top: 0px;
      left: 0px;
      width: var(--size);
      height: var(--size);
      opacity: 0;
    }
  }

  display: inline-block;
  position: relative;
  width: var(--size);
  height: var(--size);
  opacity: 0;

  transition: opacity 300ms;

  &.show {
    opacity: 1;
  }

  div {
    position: absolute;
    border: 1px solid var(--gray-700);
    opacity: 1;
    border-radius: 50%;
    animation: ldsripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}