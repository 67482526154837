.summary {
  max-width: 550px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > span,
  & > div > span {
    display: flex;
    justify-content: space-between;
  }

  & > span + span,
  & > span + div,
  & > div + span,
  & > div + div {
    border-top: 1px solid var(--gray-200);
    padding-top: 16px;
  }
}