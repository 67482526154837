@keyframes fadein {
	0% {
		opacity: 0;
   }
	20% {
		opacity: 0;
   }
	100% {
		opacity: 1;
   }
}
@-webkit-keyframes fadein {
	0% {
		opacity: 0;
   }
	20% {
		opacity: 0;
   }
	100% {
		opacity: 1;
   }
}
.app-chat {
	-webkit-animation: 0.7s ease 0s normal forwards 1 fadein;
	animation: 0.7s ease 0s normal forwards 1 fadein;
	opacity: 0;
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: 100%;
}
.app-chat.turn-on-opacity .messages {
	-webkit-animation: 0.7s ease 0s normal forwards 1 fadein;
	animation: 0.7s ease 0s normal forwards 1 fadein;
	opacity: 0;
}
.app-chat.animate-opacity .messages > app-chat-message:first-child {
	transition-property: opacity;
	transition-duration: 0.2s;
}
.app-chat.added-message .messages > app-chat-message:first-child {
	opacity: 0;
	transform: translateX(100px);
}
.app-chat .app-chat-header.chat-only {
	display: none !important;
}
.app-chat .app-chat-header > .bt-column .app-chat-person {
	padding: 13px 0;
}
.app-chat .app-chat-header > .bt-column:first-child {
	margin-left: 30px;
}
.app-chat .app-chat-header > .bt-column:last-child {
	margin-right: 30px;
}
.app-chat .app-chat-header a {
	text-decoration: none;
	color: inherit;
}
.app-chat .app-chat-header .app-chat-person {
	transition-property: all;
	transition-duration: 0.2s;
	transition-timing-function: ease-out;
	margin-right: 30px;
	display: inline-flex;
	border-bottom: 2px solid transparent;
	opacity: 0.5;
}
.app-chat .app-chat-header .app-chat-person.isDeleted .body {
	text-decoration: line-through;
}
.app-chat .app-chat-header .app-chat-person:hover {
	border-bottom: 2px solid #ff5e15;
	opacity: 1;
}
.app-chat .app-chat-header .link-active .app-chat-person {
	border-bottom: 2px solid #ff5e15;
	opacity: 1;
}
.app-chat .app-chat-header > .column-content {
	padding-top: 15px;
	padding-bottom: 20px;
}
.app-chat .app-chat-header::after {
	position: absolute;
	z-index: 1;
	content: " ";
	display: block;
	width: 100%;
	height: 10px;
	bottom: -10px;
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f0b7a1+0,8c3310+50,752201+51,bf6e4e+100;
   Brown+Gloss */
	background: linear-gradient(to bottom, #e3e3e3 0%, rgba(250, 250, 250, 0) 100%);
	color: #fafafb;
}
.app-chat .app-chat-header .company-name {
	
	color: #9399a7;
	font-size: 1.3em;
}
.app-chat .app-chat-header .company-name svg {
	margin-left: 10px;
	margin-top: -5px;
	position: relative;
	top: -2px;
}
.app-chat .messages {
	overflow: auto;
}
.app-chat #uploads-gallery-wrapper.column-uploads {
	background-color: white;
	overflow: auto;
	border-left: 1px solid #e2e2ea;
	-webkit-box-shadow: -7px 2px 5px 0px rgba(0, 0, 0, 0.11);
	-moz-box-shadow: -7px 2px 5px 0px rgba(0, 0, 0, 0.11);
	box-shadow: -7px 2px 5px 0px rgba(0, 0, 0, 0.03);
}
.app-chat .column-uploads app-chat-file-uploads {
	padding: 30px;
}
.app-chat app-chat-write-message {
	position: fixed;
	bottom: 0;
	display: block;
}
.app-chat.chat-only .column-uploads {
	display: none;
}
