.messageLog p {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.messageLog {
  display: flex;
  gap: 5px;
}
.messageLogAvatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--gray-300);
  margin-top: 5px;
}
.messageLogAvatar bt-avatar {
  width: 100%;
  height: 100%;
}
.messageLogContent {
  width: fit-content;
  padding: 10px;
  background-color: var(--gray-200);
  border-radius: 7px;
}
.messageLogName {
  color: black;
  font-weight: 500;
  margin-bottom: 5px !important;
}
.messageLogContent p:last-child {
  color: var(--gray-700);
  font-weight: 400;
}
.messageLogContent + p {
  display: inline-block;
  color: var(--gray-500);
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
}
