.massMessagingModal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 700px;
  color: var(--gray-700);
  font-size: 14px;

  & > span,
  .quickSelectContainer > span {
    color: var(--gray-900);
    font-weight: 500;
  }

  .supplierBadge span {
    font-weight: normal !important;
  }

  label {
    letter-spacing: normal;
    color: var(--gray-700);
    cursor: pointer;
  }

  .quickSelectContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .filterContainer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      align-items: center;
      justify-content: start;
      margin-left: -8px;
  
      .disabledQuickSelect {
        opacity: .5;
        pointer-events: none;
      }
    }
  }

  .treeView {
    display: flex;
    flex-direction: column;

    .indent {
      margin-left: 24px;

      display: none;

      &.show {
        .supplierArrow {
          transform: rotate(90deg);
        }

        .bigIndentation {
          display: block;
        }
      }
    }
    .smallIndentation {
      margin-left: 8px;

      &.show {
        .areaArrow {
          transform: rotate(90deg);
        }

        .indent {
          display: block;
        }
      }
    }
    .bigIndentation {
      margin-left: 40px;

      display: none;
    }

    .purchaseName {
      display: flex;
      align-items: center;
    }

    .indentationArrow {
      width: 16px;
      fill: var(--gray-700);
      cursor: pointer;
    }
  }
}