.bt-modal {
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: fixed;
  z-index: 99;

  .flex-fit {
    min-height: 0;
    min-width: 0;
  }

  .bt-modal-background {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    transition: opacity 0.5s, transform 0.5s;

    &.bt-modal-background-white {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }

  .bt-modal-body {
    opacity: 0;
    transition: opacity 0.5s, transform 0.5s;
    transform: translateY(150px);
  }

  &.bt-modal-animate {
    .bt-modal-background {
      opacity: 1;
    }

    .bt-modal-body {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &.bt-modal-show {
    display: flex;
    max-height:100000px;
  }

  .bt-modal-grid.scrollable { overflow: visible; }
  .bt-modal-grid {
    overflow: auto;
    width: 100%;
    min-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &.bt-contract {
      display: inline-flex;
      width: auto;
    }

    &.bt-debug {
      border: 1px solid red;

      .bt-modal-row {
        border: 1px solid yellow;
      }

      .bt-modal-column {
        border: 1px solid blue;
      }
    }

    @for $i from 1 through 20 {
      $value: $i * 5;
      .bt-w#{$value} {
        flex: 0;
        flex-basis: $value + 0%;
      }
    }

    .bt-modal-row {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-negative: 0;

      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;

      &.bt-contract {
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        flex-shrink: 0;
      }

      .bt-modal-column {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;

        &.bt-contract {
          flex: 0 1 auto; // Use this code also for bt-modal-row, bt-grid when contracting? This is for IE11
        }

        &.bt-center,
        .bt-center {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
        }

        &.bt-hcenter {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          align-self: flex-start;
        }

        &.bt-vcenter {
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: flex-start;
        }
      }
    }
  }
}
