.btBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  pointer-events: none;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &.bt-background-position-left {
    background-position: left;
  }

  &.bt-background-position-right {
    background-position: right;
  }

  &[class*="tint-"]:before {
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
  }

  &.tint-white:before {
    background-color: rgba(255, 255, 255, 0.4);
  }

  &.tint-black:before {
    background-color: rgba(0, 0, 0, 0.4);
  }

  &.tint-gradient-white:before {
    background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.8));
  }

  &.tint-gradient-black:before {
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  }
}
