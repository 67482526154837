.app-project-settings {
    padding: 30px;
}
.app-project-settings .app-label {
    display: block;
    margin-bottom: 40px;
    font-size: 0.8em;
}
.app-project-settings .app-label label {
    font-size: 0.86em;
    color: #7c8097;
}
.app-project-settings .app-label input {
    font-size: 1.8em;
    font-weight: normal;
}
.app-project-settings .content-left > .app-label:last-child {
    margin-bottom: 0;
}
.app-project-settings .employees .employee {
    margin-top: 10px;
    display: flex;
}
.app-project-settings .employees .employee bt-avatar {
    font-size: 16px;
    width: 40px;
    height: 40px;
}
.app-project-settings .employees .employee label {
    margin-left: 10px;
    font-size: 16px;
    color: #001659;
    text-transform: none;
}
.app-project-settings select {
    font-size: 2em;
    font-weight: normal;
    background-color: transparent;
    margin-top: 10px;
    display: block;
    border: none;
    
    border-bottom: 1px solid #e2e2ea;
    letter-spacing: 0.02em;
    color: #001659;
    width: 100%;
    padding-right: 50px;
    background-image: url(../../assets/images/dropdown-icon.png);
    background-size: 10px;
    background-position: 98% 50%;
}
.app-project-settings-custom {
    min-height: unset !important;
}