.popperContainer {
  opacity: 0%;
  visibility: hidden;
  pointer-events: none;
  transform: translate(-39px, -100%);
  top: -12px;
  left: 50%;
  z-index: 99;

  background-color: var(--white);

  filter:
    drop-shadow(0 0 1px #E5E7EB)
    drop-shadow(0 0 1px #E5E7EB)
    drop-shadow(0 0 1px #E5E7EB)
    drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
    
  border-radius: 8px;
  z-index: 99;

  pointer-events: fill;

  padding: 20px;

  .arrow {
    background-color: var(--white);
    width: 25px;
    height: 25px;
    clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);

    &[data-hide] {
      visibility: hidden;
    }

    &[data-bottom] {
      bottom: -12.5px;
    }
    &[data-top] {
      top: -12.5px;
    }
    &[data-right] {
      right: -12.5px;
    }
    &[data-left] {
      left: -12.5px;
    }
  }
}

.popperContainer {
  transition: opacity 0ms 50ms, visibility 0ms 50ms;
  &.delayed {
    transition: opacity 1000ms 0ms, visibility 0ms 1000ms;
  }
}

.referenceContainer:hover + .popperContainer,
.popperContainer:hover {
  opacity: 1;
  visibility: visible;

  &:not(.delayed) {
    transition: opacity 0ms 0ms, visibility 0ms 0ms;
  }

  &.delayed {
    transition: opacity 0ms 1000ms, visibility 0ms 1000ms;
  }
}