/* Margin Helpers */
.m-l-0 {
    margin-left: 0px; }
  
  .m-t-0 {
    margin-top: 0px; }
  
  .m-r-0 {
    margin-right: 0px; }
  
  .m-b-0 {
    margin-bottom: 0px; }
  
  .m-l-5 {
    margin-left: 5px; }
  
  .m-t-5 {
    margin-top: 5px; }
  
  .m-r-5 {
    margin-right: 5px; }
  
  .m-b-5 {
    margin-bottom: 5px; }
  
  .m-l-10 {
    margin-left: 10px; }
  
  .m-t-10 {
    margin-top: 10px; }
  
  .m-r-10 {
    margin-right: 10px; }
  
  .m-b-10 {
    margin-bottom: 10px; }
  
  .m-l-15 {
    margin-left: 15px; }
  
  .m-t-15 {
    margin-top: 15px; }
  
  .m-r-15 {
    margin-right: 15px; }
  
  .m-b-15 {
    margin-bottom: 15px; }
  
  .m-l-20 {
    margin-left: 20px; }
  
  .m-t-20 {
    margin-top: 20px; }
  
  .m-r-20 {
    margin-right: 20px; }
  
  .m-b-20 {
    margin-bottom: 20px; }
  
  .m-l-25 {
    margin-left: 25px; }
  
  .m-t-25 {
    margin-top: 25px; }
  
  .m-r-25 {
    margin-right: 25px; }
  
  .m-b-25 {
    margin-bottom: 25px; }
  
  .m-l-30 {
    margin-left: 30px; }
  
  .m-t-30 {
    margin-top: 30px; }
  
  .m-r-30 {
    margin-right: 30px; }
  
  .m-b-30 {
    margin-bottom: 30px; }
  
  .m-l-35 {
    margin-left: 35px; }
  
  .m-t-35 {
    margin-top: 35px; }
  
  .m-r-35 {
    margin-right: 35px; }
  
  .m-b-35 {
    margin-bottom: 35px; }
  
  .m-l-40 {
    margin-left: 40px; }
  
  .m-t-40 {
    margin-top: 40px; }
  
  .m-r-40 {
    margin-right: 40px; }
  
  .m-b-40 {
    margin-bottom: 40px; }
  
  .m-l-45 {
    margin-left: 45px; }
  
  .m-t-45 {
    margin-top: 45px; }
  
  .m-r-45 {
    margin-right: 45px; }
  
  .m-b-45 {
    margin-bottom: 45px; }
  
  .m-l-50 {
    margin-left: 50px; }
  
  .m-t-50 {
    margin-top: 50px; }
  
  .m-r-50 {
    margin-right: 50px; }
  
  .m-b-50 {
    margin-bottom: 50px; }
  
  .m-l-55 {
    margin-left: 55px; }
  
  .m-t-55 {
    margin-top: 55px; }
  
  .m-r-55 {
    margin-right: 55px; }
  
  .m-b-55 {
    margin-bottom: 55px; }
  
  .m-l-60 {
    margin-left: 60px; }
  
  .m-t-60 {
    margin-top: 60px; }
  
  .m-r-60 {
    margin-right: 60px; }
  
  .m-b-60 {
    margin-bottom: 60px; }
  
  .m-l-65 {
    margin-left: 65px; }
  
  .m-t-65 {
    margin-top: 65px; }
  
  .m-r-65 {
    margin-right: 65px; }
  
  .m-b-65 {
    margin-bottom: 65px; }
  
  .m-l-70 {
    margin-left: 70px; }
  
  .m-t-70 {
    margin-top: 70px; }
  
  .m-r-70 {
    margin-right: 70px; }
  
  .m-b-70 {
    margin-bottom: 70px; }
  
  .m-l-75 {
    margin-left: 75px; }
  
  .m-t-75 {
    margin-top: 75px; }
  
  .m-r-75 {
    margin-right: 75px; }
  
  .m-b-75 {
    margin-bottom: 75px; }
  
  .m-l-80 {
    margin-left: 80px; }
  
  .m-t-80 {
    margin-top: 80px; }
  
  .m-r-80 {
    margin-right: 80px; }
  
  .m-b-80 {
    margin-bottom: 80px; }
  
  .m-l-85 {
    margin-left: 85px; }
  
  .m-t-85 {
    margin-top: 85px; }
  
  .m-r-85 {
    margin-right: 85px; }
  
  .m-b-85 {
    margin-bottom: 85px; }
  
  .m-l-90 {
    margin-left: 90px; }
  
  .m-t-90 {
    margin-top: 90px; }
  
  .m-r-90 {
    margin-right: 90px; }
  
  .m-b-90 {
    margin-bottom: 90px; }
  
  .m-l-95 {
    margin-left: 95px; }
  
  .m-t-95 {
    margin-top: 95px; }
  
  .m-r-95 {
    margin-right: 95px; }
  
  .m-b-95 {
    margin-bottom: 95px; }
  
  .m-l-100 {
    margin-left: 100px; }
  
  .m-t-100 {
    margin-top: 100px; }
  
  .m-r-100 {
    margin-right: 100px; }
  
  .m-b-100 {
    margin-bottom: 100px; }
  
  .m-l-105 {
    margin-left: 105px; }
  
  .m-t-105 {
    margin-top: 105px; }
  
  .m-r-105 {
    margin-right: 105px; }
  
  .m-b-105 {
    margin-bottom: 105px; }
  
  .m-l-110 {
    margin-left: 110px; }
  
  .m-t-110 {
    margin-top: 110px; }
  
  .m-r-110 {
    margin-right: 110px; }
  
  .m-b-110 {
    margin-bottom: 110px; }
  
  .m-l-115 {
    margin-left: 115px; }
  
  .m-t-115 {
    margin-top: 115px; }
  
  .m-r-115 {
    margin-right: 115px; }
  
  .m-b-115 {
    margin-bottom: 115px; }
  
  .m-l-120 {
    margin-left: 120px; }
  
  .m-t-120 {
    margin-top: 120px; }
  
  .m-r-120 {
    margin-right: 120px; }
  
  .m-b-120 {
    margin-bottom: 120px; }
  
  .m-l-125 {
    margin-left: 125px; }
  
  .m-t-125 {
    margin-top: 125px; }
  
  .m-r-125 {
    margin-right: 125px; }
  
  .m-b-125 {
    margin-bottom: 125px; }
  
  .margin-0 {
    margin: 0; }

   .d-flex{
     display: flex;
   } 
   .justify-content-center{
     justify-content: center;
   }
  .align-items-center{
    align-items: center;
  }
  .position-absolute{
    position: absolute;
  }
  .position-relative{
    position: relative;
  }
