.legends {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 27px 0px;
}
.legend-item {
  display: flex;
  width: max-content;
  align-items: center;
  margin-left: 20px
}
.legend-color {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  border-radius: 50%;
}
.legend-label {
  font-family: var(--primaryFont);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color:var(--gray-900);
}
