bt-check {
  .checkmark {
    .checkmark__circle,
    .checkmark__check {
      transition: all 0.1s;
    }

    .checkmark__circle {
      stroke: #ff5e15;
      fill: transparent;
      stroke-dashoffset: 0;
    }
  }
  &[checked] .checkmark {
    .checkmark__circle {
      fill: #ff5e15;
    }
    .checkmark__check {
      stroke: var(--white);
      animation: stroke 300ms cubic-bezier(0.65, 0, 0.45, 1) 150ms forwards;
    }
  }
}

.page-quotation-status-div {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* File uploader */
.quote-file-upload-container .item-overflow .item svg {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  opacity: 0.3;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
  top: -1px;
  vertical-align: super;
}
.quote-file-upload-container .item-overflow {
  margin-right: 25px;
  margin-bottom: 10px;
}
.quote-file-upload-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 17px 0px;
}
.quote-file-upload-container .btn-attach-wrapper {
  margin-top: 0px;
  color: var(--gray-500);
  font-weight: 500;
  font-size: var(--fontSize-14);
}
.quote-file-upload-container .upload-files-wrapper {
  width: 80%;
}
.quote-file-upload-container .upload-files-wrapper .bt-filetype {
  padding: 5px 10px 1px 10px;
  border: 1px solid var(--gray-200);
  border-radius: 6px;
}

/* Collapsible View */
.quote-collapsible {
  transition: 0.3s;
  margin-bottom: 12px;
}
.quote-header-collapsible {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quote-title-collapsible {
  display: block;
  font-size: var(--fontSize-16);
  font-weight: 500;
  width: 95%;
}
.quote-title-collapsible .padded-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.quote-title-collapsible .quote-status {
  font-size: var(--fontSize-12);
  font-weight: 500;
  color: var(--gray-900);
}

.quotation-main-box .rdw-editor-toolbar .rdw-link-modal {
  height: 255px;
}
.quotation-main-box .rdw-editor-toolbar .rdw-embedded-modal {
  height: 200px;
}
.quotation-main-box .rdw-editor-toolbar {
  background: var(--gray-50);
    margin-right: 0px !important;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-bottom: 0px;
    border: 1px solid var(--gray-300);
}
.quotation-main-box .rdw-editor-toolbar .rdw-option-wrapper:not(.rdw-colorpicker-modal-options .rdw-option-wrapper)
{
  padding: 12px 9px;
  border-radius: 6px;
  border: 1px solid var(--gray-300);
}
.quotation-main-box .rdw-editor-toolbar .rdw-dropdown-wrapper{
  border-radius: 6px;
}
.quotation-main-box .rdw-editor-toolbar .rdw-option-wrapper img {
  width: 12px;
  height: 12px;
}
.quote-price-column label {
  color: var(--gray-700);
}
span.headline-label.quotation-v2-price {
  color: var(--gray-700);
  letter-spacing: normal;
}
.quotation-v2-price-label .headline-sub-label {
  font-weight: 400;
  color: var(--gray-900);
  margin-top: 8px;
  margin-bottom: 8px;
  letter-spacing: normal;
}
.quoteValidityContainer {
  font-weight: 500;
  color: var(--gray-700);
  width: 80%;
  margin: 20px 0px 35px 0px;
}
.page-quotation-demands-label {
  min-height: 45px;
  width: fit-content;
  display: flex;
  align-items: center;
}
#quotation-editor-wrapper .rdw-editor-main {
  height: 215px !important;
  overflow-y: auto;
  border-left: 1px solid var(--gray-300);
  border-right: 1px solid var(--gray-300);
  border-bottom: 1px solid var(--gray-300);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.quotation-form-container bt-button.btn-send {
  width: fit-content;
}

/* content-block2 css */
.content-block2 {
  font-size: var(--fontSize-12);
  color: var(--gray-700);
}
.content-block2 .sub-block {
  align-items: center;
  display: flex;
  gap: 15px;
}
.content-block2 .sub-block span {
  align-items: center;
  display: flex;
  gap: 5px;
}
.files-viewer-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
  width: 100%;
  flex-wrap: wrap;
}
.files-sub-container {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.files-viewer-container bt-filetype.bt-filetype.hydrated {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 159px;
  height: 28px;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  margin-bottom: 6px;
}
.files-viewer-container bt-filetype.bt-filetype.hydrated a {
  display: flex;
  align-items: center;
}
.files-viewer-container bt-filetype.bt-filetype.hydrated .icon {
  width: 16px;
  height: 16px;
  display: inline-block;
}
.files-viewer-container bt-filetype.bt-filetype.hydrated .description {
  margin-top: 0px;
  font-size: var(--fontSize-12);
}
.bottom-text {
  font-size: var(--fontSize-12);
  line-height: 16px;
  text-decoration-line: underline;
  color: var(--gray-700);
  margin-top: 8px;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quoteValidityPickerContainer {
  display: flex;
  align-items: center;
  border: 1px solid var(--gray-300);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
  border-radius: 6px;
  width: fit-content;
  margin-top: 7px;
}
.quoteValidityPickerContainer .quickPicker{
    align-items: center;
    justify-content: center;
    display: flex;
    background: var(--gray-50);
    padding: 11px 17px;
    border-right: 1px solid var(--gray-300);
    cursor: pointer;
}
.quoteValidityPickerContainer .quickPickerDisabled{
  cursor: not-allowed;
}
.quoteValidityPickerContainer .left-border{
  border-left: 1px solid var(--gray-300);
}

.quoteValidityPickerContainer .quickPicker:last-of-type{
  border-right: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.quoteValidity-date-picker-container{
  width: fit-content;
  margin-left: 12px;
}

.pickerIconContainer {
  display: flex;
  padding-left: 16px;
  align-items: center;
}
.quoteValidity-date-picker-container input {
  background-color: transparent;
  width: inherit;
  color: var(--gray-700);
  font-size: var(--fontSize-14);
}
.quoteValidityPickerContainer .ar-label.ar-select-label {
  padding: 9px 0px;
}
.quoteValidity-date-picker-container input::placeholder {
  color: var(--gray-500);
}
.calendar-wrapper small {
  text-decoration: underline;
  cursor: pointer;
}
.calendar-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.zipDownloadProgressContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 165px;
  gap: 5px;

  span {
    color: var(--gray-700);
    font-size: 12px;
  }
}