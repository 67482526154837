.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .areaContainer {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    justify-content: space-between;

    .areaSelector {
      width: 300px;
    }

    & > *:last-child {
      margin-bottom: 6px;
    }
  }
}