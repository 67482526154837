.pageHelp {
  margin: 32px;
  .container {
    padding: 24px;
    background-color: white;
    border: 1px solid var(--gray-300);
    box-shadow: 0px 1px 2px 0px #0000000f, 0px 1px 3px 0px #0000001a;
    border-radius: 8px;
  }

  h3 {
    color: var(--gray-900);
    font-weight: 600;
  }

  p {
    color: var(--gray-900);
    font-size: 16px;
  }
}