.list {
  border-radius: 6px;
  background-color: white;
  font-size: 14px;
  margin: 32px 0;
  padding: 0;
  color: var(--gray-700);
  border: 1px solid var(--gray-300);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

  li {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid var(--gray-200);

    .buttons {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  li.empty {
    justify-content: center;
    padding: 16px 0px;
  }
}
