.container {
  display: grid;
  grid-template-columns: 1fr 100px;
  align-items: center;
  width: 100%;
  gap: 10px;

  &.blockedSupplier .form {
    opacity: .5;
  }

  &.isRemoved .form {
    opacity: .3;
    pointer-events: none;

    .boldText,
    .lightText {
      text-decoration: line-through;

      input {
        text-decoration: line-through;
      }
    }
  }

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
    column-gap: 30px;
    z-index: 2;

    .lightText > *:first-child {
      font-size: var(--fontSize-12);
    }
    .boldText > *:first-child {
      font-weight: 500;
      margin: 0;
    }
  }

  .buttonsDiv {
    display: flex;
    justify-content: center;
    gap: 10px;

    &.hideButton > div:first-child {
      visibility: hidden;
      transition: none;
    }
  }
}