.informationCard {
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

.informationCard {
  font-size: 16px;
  li {
    margin: 4px 0;
  }
}

.activeRecommendedAds, .activeFeatureSupplierAd {
  color: var(--gray-700);
  display: flex;
  flex-direction: column;
  gap: 16px;
  .activeRecommendedAdsItem + .activeRecommendedAdsItem {
    border-top: 1px solid var(--gray-200);
  }
  p {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .activeRecommendedAdsItem > span {
    font-weight: 400;
    font-size: 14px;
  }
}

.result {
  font-size: 14px;
  color: var(--gray-700);
  thead {
    text-align: left;

    th {
      font-weight: 500;
    }
  }
  td:not(:first-child) {
    padding: 24px 0;
  }
  tbody {
    tr {
      border-bottom: 1px solid var(--gray-200);
    }
  }
}

.logo_grid_icon {
  width: 100%;
  max-width: 120px;
  filter: grayscale(1);
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.c_logo_grid_icon_wrapper {
  height: 120px;
  justify-content: center;
  align-items: center;
  padding: 32px;
  display: flex;
}

.c_logo_grid_list {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-wrap: wrap;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: flex-start;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.list {
  li {
    padding: 16px 0;
  }
}

.card {
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  color: var(--gray-700);
  font-size: 14px;

  h3 {
    font-size: 18px;
    font-weight: 500;
    color: var(--gray-900);
  }
  b {
    font-weight: 500;
    color: var(--gray-900);
  } 
}

.logo_grid_icon {
  width: 100%;
  max-width: 120px;
  filter: grayscale(1);
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.c_logo_grid_icon_wrapper {
  height: 120px;
  justify-content: center;
  align-items: center;
  padding: 32px;
  display: flex;
}

.c_logo_grid_list {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-wrap: wrap;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: flex-start;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.list {
  margin: 0;
  li {
    padding: 8px 0;
  }
}

.card {
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  color: var(--gray-700);

  h3 {
    font-size: 18px;
    font-weight: 500;
    color: var(--gray-900);
  }
  b {
    font-weight: 500;
    color: var(--gray-900);
  } 
}

.activeFeatureSupplierAd {
  & > a {
    margin-top: 16px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-left-color: green;
  border-right-color: green;

  animation: rotation 2s ease-in-out infinite;
}