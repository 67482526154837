.container {
  display: flex;
  align-items: center;
  gap: 8px;

  .modalContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .conversationContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      max-height: 50vh;
      overflow-y: scroll;
      padding: 0 8px;
      scroll-behavior: smooth;

      &.readOnly {
        max-height: 70vh;
      }
    }
  }

  &>a {
    color: var(--gray-700);
  }
}

.message {
  display: flex;
  gap: 8px;

  p {
    margin: 0;
  }

  &:not(.minified) {
    width: 100%;

    .messageDetails {
      max-width: 70%;
    }
  }

  &.minified .messageDetails > div > div {
    max-height: 35px;
    max-width: 400px;
    overflow: hidden;
  }

  &.rightAligned {
    flex-direction: row-reverse;

    .messageDetails {
      align-items: flex-end;

      & > div {
        align-items: flex-end;
      }
    }
  }

  .messageDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--gray-700);
    gap: 4px;

    & > div {
      display: flex;
      flex-direction: column;
      background-color: var(--gray-100);
      border-radius: 8px;
      padding: 8px;

      .name {
        font-weight: 600;
        color: var(--gray-900);
      }
    }

    & > span {
      font-size: 12px;
    }
  }
}