.container {
  display: grid;
  align-items: center;
  margin: 30px;
  gap: 20px;

  .content {
    min-width: 600px;
    width: 100%;
    max-width: 1180px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    strong {
      color: var(--gray-900);
      font-weight: 500;
    }

    .supplierList {
      display: flex;
      flex-direction: column;

      padding: 20px;

      & > label {
        display: flex;
        align-items: center;
        gap: 25px;
        padding: 10px 0px;
        cursor: pointer;

        div {
          display: flex;
          flex-direction: column;

          span {
            letter-spacing: normal;
          }
          span:nth-child(1) {
            color: var(--gray-900);
            font-weight: 500;
          }
          span:nth-child(2) {
            font-size: 12px;
            color: var(--gray-500);
          }
        }
      }
    }

    .emailContainer {
      padding: 30px;
      font-size: 14px;
      color: var(--gray-700);

      hr {
        margin: 25px 0;
        height: 1px;
        border: none;
        background-color: var(--gray-300);
      }

      & > a {
        display: block;
        font-size: 20px;
        margin: 25px 0;
      }

      p {
        margin: 0;

        &.margin {
          margin-bottom: 20px;
        }

        &.bold {
          font-weight: 500;
          color: var(--gray-900);
        }
      }
    }
    .recipients {
      padding: 24px 32px;

      .recipient {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        &>div {
          width: 50%;
        }
        strong {
          font-size: 14px;
        }
        span {
          color: var(--gray-500);
        }  
      }

      & > div:last-of-type { /* Add recipient button */
        margin-top: 8px;
      }
    }

    .questions {
      & > div + div {
        border-top: 1px solid var(--gray-300);
      }
      & > div {
        padding: 24px 32px;
      }

      .titleWrapper {
        & > p:first-child {
          margin-bottom: 12px;
          font-size: var(--fontSize-12);
        }
      }
    }
  }
}