.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex: 1;
  margin: 30px 20px 30px 30px;

  .title {
    color: var(--gray-900);
    font-weight: var(--weight-600);
    font-size: var(--fontSize-18);
  }
}