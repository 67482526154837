.custom-editor {
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}

.app-select-industry-types
  .colum-left
  .content-left
  .content-left
  bt-grid
  > bt-row
  > bt-column:last-child
  .page-quotation-industry-items {
    
    margin-right: 0;
  }
  .page-quotation-industry-items bt-check .checkmark {
    right: unset;
  }
  
  .page-quotation-industry-item bt-check label::after {
    transform: none;
    height: 0px;
  }
  .page-quotation-industry-item bt-check label .name {
    margin-left: 20px;
    color: var(--gray-900);
    font-size: var(--fontSize-14);
    font-weight: var(--weight-400);
    text-transform: none;
}


.app-select-industry-types .page-quotation-industry-items {
  margin-right: 30px;
  flex-direction: column;
}

.app-select-industry-types .page-quotation-industry-items .page-quotation-industry-item {
  position: relative;
}

.app-select-industry-types
  .page-quotation-industry-items
  .page-quotation-industry-item:hover
  btn-remove-industry {
  opacity: 1 !important;
}

.app-select-industry-types .page-quotation-industry-items .page-quotation-industry-item btn-remove-industry {
  cursor: pointer;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  display: none;
  opacity: 0;
  position: absolute;
  padding-right: 10px;
  right: -20px;
  z-index: 1;
  top: 48%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: " ";
  background-image: url(../../assets/svg/delete-ring.svg);
  height: 14px;
  width: 14px;
  background-repeat: no-repeat;
}

.page-quotation-industry-items {
  display: flex;
}

.page-quotation-industry-items li.page-quotation-industry-item {
  padding-right: 20px;
  margin-right: 20px;
}


.page--quotation .page-quotation-industry-item bt-check .checkmark .checkmark__check {
  visibility: hidden;
  transition: all 0.3s;
}

.page--quotation .page-quotation-industry-item bt-check[checked] .checkmark .checkmark__check {
  visibility: visible;
  stroke: #fff;
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.page--quotation .page-quotation-industry-item bt-check .checkmark .checkmark__circle {
  transition: all 0.3s;
  stroke: var(--gray-300);
  fill: transparent;
  stroke-dashoffset: 0;
}

.page--quotation
  .page-quotation-industry-item
  bt-check[checked]
  .checkmark
  .checkmark__circle {
  stroke: #ff5e15;
  fill: #ff5e15;
}

label.ar-title-dropdown {
  display: flex;
  align-items: center;
}

label.ar-title-dropdown .supplier-version-section.sl-version-selector {
  margin-right: 20px;
  margin-bottom: 0 !important;
}

label.ar-title-dropdown .supplier-version-section.sl-version-selector ul li {
  font-size: 16px;
  line-height: 16px;
  
  font-weight: 500;
}
.quotation-form-container .form-content {
  position: relative;
  margin-top: 24px;
}

.form-content.disabledPage {
  cursor: not-allowed;
}

.quotation-uploaded-files {
  display: flex !important;
  gap: 12px;
  flex-wrap: wrap;
}

.form-content.disabledPage .upload-files-wrapper.quotation-uploaded-files {
	pointer-events: fill;
}
.form-content.disabledPage .message-wrapper .rdw-editor-main {
  pointer-events: fill;
}

.bidFormFooter {
  align-items: center;
  display: flex;
  flex-direction: column;
  color: var(--gray-700);
  font-size: 12px;
  justify-content: center;

  margin-top: 40px;
  margin-bottom: 100px;
}

.bidFormFooter a {
  color: var(--gray-700);
  text-decoration: underline;
}

.bidFormFooter p {
  text-align: center;
}

.menu-left.quotation--menu {
  background-color: var(--gray-800);
  width: 245px;
  position: fixed;
}
.menu-left.quotation--menu .logo-wrapper {
  border-bottom: 0px;
  margin: 40px 0px;
}
.quotation-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 26px;
  color: var(--gray-900);
  border-bottom: 1px solid #D1D5DB;
  font-size: var(--fontSize-16);
}
.info-head-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
}
.info-head-container .title {
  font-size: var(--fontSize-14);
  color: var(--gray-500);
  margin-bottom: 6px;
}
.info-head-container .content {
  font-weight: var(--weight-600);
}
.generic-card.quotation-info-head1 {
  margin: 26px 0px 24px 0px;
}
.quotation-body {
  min-width: 600px;
  width: 100%;
  max-width: 1180px;
  font-size: var(--fontSize-14);
  color: var(--gray-900);
}
.info-content-container .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-content-container .padded-block .title {
  font-size: var(--fontSize-16);
  font-weight: var(--weight-500);
}

.info-content-container .padded-block .exp-date {
  display: flex;
  align-items: center;
  gap: 4px;
}
.generic-card.quotation-info-content-container {
  padding: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border: 0px;
}

.info-content-container .padded-block {
  display: flex;
  justify-content: space-between;
  padding: 27px 28px 27px 28px;
  border-bottom: 1px solid var(--gray-300);
}
.quotation-label-head {
  font-size: var(--fontSize-12);
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 9px;
  letter-spacing: 0.05em;
  color: var(--gray-900);
  line-height: 16px;
}
.quotationCalendarIcon {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
  background-color: transparent;
}
.padded-block.content-block3 .quotation-information {
  font-size: var(--fontSize-14);
  color: var(--gray-700);
}
.padded-block.content-block3 {
  padding: 0px 28px 0px 28px;
}
.quotation-label-block-container.information {
  width: 60%;
  padding: 27px 50px 0px 0px;
  border-right: 1px solid var(--gray-300);
  height: inherit;
}
.quotation-label-block-container.files-container {
  width: 40%;
  padding: 27px 0px 0px 27px;    
  height: inherit;
}
.quotation-header {
  font-size: var(--fontSize-18);
  font-weight: var(--weight-400);
}
.quotation-header .project-head {
  font-weight: var(--weight-600);
  margin-right: 4px;
}
.quotation-header .sup-info {
  font-weight: var(--weight-600);
  font-size: var(--fontSize-16);
}


/* QUOTATION FORM CSS */
.quotation-form-container {
  padding: 23px 32px 28px 32px;
  
}
.quotation-form-container .heading {
  font-weight: var(--weight-500);
  font-size: var(--fontSize-16);
  color: var(--gray-900);
}
.quotation-main-box .message .message-wrapper {
  padding: 0px;
  border: 0px;
  height: 295px;
  width: unset;
}
.quotation-main-box .btn-attach-wrapper svg {
  vertical-align: middle;
  height: 14px;
  fill: var(--gray-500);
}
.quotation-form-container .quote-price-container {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
  margin-bottom: 16px;
  margin-top: 16px;
}
.quotation-form-container bt-row.quote-price-row .quote-price-column {
  display: flex;
  flex-direction: column;
  flex: 0.49;
}
.quote-price-column .form-group input {
  border-radius: 6px;
  margin-top: 4px;
  margin-bottom: 29px;
}
.bidFormNavLink span {
  color: var(--gray-400);
}
.bidFormNavLink.bidFormNavLinkSelected span {
  color: var(--gray-100);
}
.page-quotation-content {
  width: calc(100% - 245px);
  margin-left: 245px;
}
.page-quotation-content .scrollable-content {
  margin-left: 0;
}
