.filterContainer {
  display: flex;
  gap: 16px;

  font-size: 14px;
  font-weight: 400;

  & + .filterContainer {
    margin-top: 32px;
  }

  .filterText {
    width: 220px;
    color: var(--gray-700);
  }

  .filterFieldContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 8px;

    .filterField {
      height: fit-content;
    }

    .badgeContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  .filterTypeSelector,
  .filterField {
    width: 525px;
  }
}

.badge {
  display: inline-flex;
  padding: 2px 2px 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;

  border-radius: 10px;
  background: var(--gray-100);

  svg {
    border-radius: 50%;
    color: var(--gray-400);
    width: 14px;
    height: 14px;
    cursor: pointer;

    &:hover {
      background-color: var(--gray-300);
    }
  }
}