.supplierHeaderRow {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-top: 46px;
  margin-right: 30px;
  border-bottom: 1px solid var(--gray-300);
  
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: var(--gray-900);
  }
  
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-900);
  }
}

