.container {
  width: 100%;
  display: grid;
  grid-template-columns: calc(25% - 16px) calc(25% - 16px) calc(25% - 16px);
  grid-template-rows: 1fr 1fr 1fr;

  gap: 16px;
}

.tabContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
}

.chartContainer {
  display: flex;
  flex-wrap: wrap;
  
  gap: 16px;

  & > div:nth-child(odd) {
    width: calc(50% - 16px);
  }
  & > div:nth-child(even) {
    width: calc(25% - 16px);
    margin: 0;
  }
}