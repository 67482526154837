.container {
  margin: 30px;

  .loader {
    display: grid;
    place-items: center;
    height: 80vh;
  }
}

.tabContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
