.app-supplier-database-mini {
  background: #fff;
  overflow: auto;
  padding: 5px;
  height: 100%;
  max-width: 600px;
}

.app-supplier-database-mini .hb-bwrapper > bt-grid > bt-row {
  width: 100%;
  padding: 0;
  margin-left: -75px;
}

.app-supplier-database-mini .hb-bwrapper > bt-grid > bt-row > bt-column {
  width: 100%;
}

.app-supplier-database-mini .hb-bwrapper .filter-actions {
  transform: scale(0.7);
}

.app-supplier-database-mini .filter-actions bt-column.left,
.app-supplier-database .filter-actions .bt-select-wrapper .bt-select {
  margin-right: 10px;
}

.app-supplier-database-mini .hero-banner .hb-bwrapper {
  height: 160px;
}

.app-supplier-database-mini .the-table {
  margin-top: 0;
}

.app-supplier-database-mini .the-table table tbody td:first-child {
  width: auto;
}
.blacklist-warning {
  /* width: 26px; */
  margin-right: 10px;
}
.rating i {
    /* display: inline; */
    position: relative;
  	color: rgb(255, 94, 21);
  	font-size: 1.5em;
}
.textCenter {
  text-align: center !important;
}

 .fa-star-o:before {
  color: var(--gray-250)
}
 .fa-star:before {
  color: var(--orange-500)
}

.customDrpD.bt-select {
  margin-left: 10px;
}
.customDrpD .css-1domaf0{
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.customDrpD .css-1rtrksz{
  padding: 14px 15px;
  padding-bottom: 13px;
}
.customDrpD .css-d8oujb{
  margin: 0px;
}
.customDrpD .css-1ep9fjw{
  padding: 14px 5px;
}

.app-supplier-database .hb-bwrapper .filter-actions .bt-search {
  background-color: #fff;
  max-width: 200px;
}

.ar-row.sp-detail-bottom-row .app-supplier-database-expand {
  width: 100%;
  position: relative;
}

.miniDatabaseRow {
  cursor: pointer;
}
.miniDatabaseRow.selected td {
  color:rgb(255, 94, 21);
}