.container :global {
  .MuiTable-root tr.MuiTableRow-root.MuiTableRow-head th:nth-child(1),
  .supplier-db-main-table-wrap .MuiTable-root tr.MuiTableRow-root.MuiTableRow-head th:nth-child(3) {
    display: table-cell !important;
  }
  .MuiButtonBase-root.MuiTableSortLabel-root {
    display: flex;
    justify-content: space-between;
  }
  .MuiTableSortLabel-root:hover,
  .MuiTableSortLabel-root:focus {
    color: unset;
  }
  .MuiTableSortLabel-root.Mui-active {
    color: #ff5e15;
  }
  .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
    color: var(--orange-500);
  }

  .MuiTableSortLabel-root:not(.Mui-active) .MuiSvgIcon-root {
    display: none;
  }
  .supplier-pagination {
    display: flex;
    justify-content: center;
  }

  .MuiTableBase-root {
    font-weight: 500;
  }
  .MuiTableCell-root {
    padding: 12px 16px !important;
  }
  .MuiTableRow-root {
    font-size: 14px;
    color: var(--gray-700) !important;
  }
  .MuiTableCell-head {
    color: var(--gray-900) !important;
    font-weight: 500;
    padding: 10px 16px !important;
    position: static;
    background-color: var(--gray-50) !important;
  }
  .MuiTableCell-head:first-child {
    border-top-left-radius: 7px;
  }
  .MuiTableCell-head:last-child {
    border-top-right-radius: 7px;
  }

  .MuiTableBody-root .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: rgba(245, 245, 245, 0.4) !important;
  }
  .projectStartDeleteProject {
    visibility: hidden;
  }
  .pageProjectStartContainer .MuiTableBody-root .MuiTableRow-root:not(:only-child):hover .projectStartDeleteProject {
    visibility: visible;
  }
  .MuiTableCell-head:last-child {
    width: 50px !important;
  }
  .MuiPaper-root > div > div {
    border-radius: 8px;
  }
  .MuiTableRow-head {
    border-bottom: 1px solid var(--gray-200);
  }
  .MuiTableSortLabel-root {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    flex-direction: inherit;
    justify-content: flex-start;
  }
}

.container {

  &.isScrollable {
    max-height: calc(100vh - 300px);
    overflow: auto;
    width: calc(100vw - 320px);

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--gray-200);
    border-radius: 8px;

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      border-radius: 20px;
      background-color: white;
    }
    
    &::-webkit-scrollbar:horizontal {
      border-radius: 0 0 0 8px;
      border-top: 1px solid var(--gray-200);
    }
    
    &::-webkit-scrollbar:vertical {
      border-radius: 0 8px 0 0;
      border-left: 1px solid var(--gray-200);
    }
    
    &::-webkit-scrollbar-corner {
      background-color: white;
      border-radius: 0 0 8px 0;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: var(--gray-300);
      border-radius: 8px;
    }
    
    &::-webkit-scrollbar-thumb:horizontal {
      background-clip: padding-box;
      border-top: 3px solid transparent;
      border-bottom: 2px solid transparent;
    }
    
    &::-webkit-scrollbar-thumb:vertical {
      background-clip: padding-box;
      border-left: 3px solid transparent;
      border-right: 2px solid transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--gray-400);
      border-width: 2px 1px 1px 2px;
    }
    
    :global .MuiPaper-root {
      border-radius: 0 !important;
      box-shadow: none !important;
      border: 0 !important;

      &> div > div {
        border-radius: 8px 0 0 0;
      }
    }
    
    thead tr {
      white-space: nowrap;
    }
    
    thead th {
      position: sticky !important;
      top: 0;
      z-index: 1;
      border-bottom: 1px solid var(--gray-300);
    }
  }
}