.app-chat-start {
  display: block;
  width: 100%;
}
.app-chat-start svg {
  margin-right: 10px;
  top: 1px;
  position: relative;
  transition: all 1s ease-in-out;
  animation: ani 1.2s infinite;
}
.app-chat-start p {
  margin-top: 120px;
}
.app-chat-start .bg {
  position: absolute;
  left: 40px;
  bottom: -5px;
}
.app-chat-start .bg img {
  width: 400px;
}
@media only screen and (max-device-width: 400px) and (orientation: portrait) {
  .app-chat-start .bg img {
    max-width: 800px !important;
 }
}
@keyframes ani {
  0% {
    left: 0;
 }
  50% {
    left: -10px;
 }
  100% {
    left: 0;
 }
}
