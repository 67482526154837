.profile-wrapper {
  width:50%;
}
.change-password-wrapper {
  width:70%;
}
.change-password-wrapper .app-label input{
  background-color: white !important;
}
.settings-container {
  padding: 30px;
}

.settings-container .rdw-editor-wrapper{
  margin-bottom: 20px;
} 
.settings-container .MuiTabs-root{
  border-bottom: 1px solid #d8d8d8 !important;
  margin: 0 24px !important;
}
.settings-container .MuiTab-root{
  min-width: min-content !important; 
}
.settings-container .MuiTab-root{
padding-left: 0 !important;
}
.settings-container .MuiTab-root:first-of-type{
  margin-right: 20px;
}