.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    align-items: center;

    img {
      transform: scale(.7);
    }
  
    p {
      &:first-child {
        color: var(--brandBlack);
        max-width: 255px;
        font-size: 35px;
        margin-top: 0;
        letter-spacing: 0.02em;
        line-height: 40px;
        font-weight: 700;
      }
      &:last-child {
        color: var(--gray-900);
        max-width: 320px;
      }
    }
  }
}