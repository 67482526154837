.documentLink {
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-700);
  cursor: pointer;
  border-bottom: 1px solid var(--gray-200);
  padding-bottom: 16px;

  img {
    vertical-align: middle;
  }
}

.documentLink:last-child {
  border-bottom-width: 0;
  padding-bottom: 0;
}

.row {
  display: flex; 
  gap: 32px;
}

.row>div { 
  flex: 1; 
}

.noVersions {
  color: var(--gray-500);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

table.documents {
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-700);

  img {
    vertical-align: middle;
  }


  th { 
    font-weight: 500; 
    text-align: left;
    padding-bottom: 16px;
    color: var(--gray-900);
  }
  tbody>tr>td,tbody>tr>th {
    border-top: 1px solid var(--gray-200);
    cursor: pointer;
    padding: 16px 0;
    width: 10%;
  }
  tbody>tr>td {
    font-weight: 400;
  }
}

.projectAreaDatePicker {
  border: none;
  background-color: var(--gray-100);
  width: 230px;
}


.datesControls {
  margin-top: 20px;

  & > span {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    display: block;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}