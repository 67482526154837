.card {
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  padding: 32px;
  border-radius: 6px;
  margin-bottom: 32px;

  .title {
    white-space: nowrap;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    max-width: fit-content;    
  }
}