.sp-action-icon {
  position: relative;
}
.trigger,
.defaultTrigger {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
.trigger {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}
.sp-action-icon a,
.defaultTrigger {
  display: grid;
  place-items: center;
  color: var(--gray-500);
  text-decoration: none;
  transition: background-color ease-in-out;
}
.defaultTrigger {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.sp-action-icon a:hover,
.defaultTrigger:hover {
  background-color: var(--gray-100);
}

.sp-action-menu {
  background-color: #fff !important;
  border: 1px solid var(--gray-300);
  border-radius: 7px;
  padding: 4px 0;

  font-family: var(--primaryFont);
  font-size: 14px;
  font-weight: normal;

  display: none;
  position: absolute;
  right: 0;
  top: calc(100% + 5px);
  z-index: 99;

  min-width: 200px;

  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.sp-action-menu.menuLeft {
  right: unset;
  left: 0;
}
.sp-action-icon:focus-within .sp-action-menu {
  display: block;
}
.sp-action-menu a.sp-action-menu-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  gap: 10px;
  text-align: center;
  padding: 5px 10px;
  color: var(--gray-700);
}
.sp-action-menu a.sp-action-menu-item span {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sp-action-menu a.sp-action-menu-item i,
.sp-action-menu a.sp-action-menu-item svg {
  min-width: 15px;
  min-height: 15px;
  fill: var(--gray-700);
}
.sp-action-menu a.sp-action-menu-item.optionsMenuSelected {
  background-color: var(--gray-50);
}