.toolbarContainer {
  --radius: 6px;
  display: flex;
  border: 1px solid var(--gray-300);
  border-radius: var(--radius);
}
.toolbarContainer > div:first-child {
  border-top-left-radius: calc(var(--radius) - 1px);
  border-bottom-left-radius: calc(var(--radius) - 1px);
}
.toolbarContainer > div:last-child {
  border-top-right-radius: calc(var(--radius) - 1px);
  border-bottom-right-radius: calc(var(--radius) - 1px);
}
.toolbarContainer > div + div {
  border-left: 1px solid var(--gray-300);
}

.toolbarContainer > div {
  display: grid;
  place-items: center;
  width: fit-content;
  height: 32px;
  min-width: 38px;
  background-color: var(--gray-50);
  cursor: pointer;
}

.toolbarContainer > div:hover {
  background-color: var(--gray-100);
}

.toolbarContainer > div.toolbarOptionDisabled_AllowHover,
.toolbarContainer > div.toolbarOptionDisabled_Fully {
  cursor: default;
  pointer-events: none;
}
.toolbarOptionDisabled_Fully * {
  pointer-events: none;
}
.toolbarOptionDisabled_AllowHover:active {
  pointer-events: none;
}