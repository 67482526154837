.member-modal {
  max-width: 500px;
}

.member-modal .accSelector-container {
  margin: 12px 0px 12px 0px;
}

.member-modal .disabled {
  opacity: .5;
  pointer-events: none !important;
}

.member-modal .error p {
  margin: -20px 0px 0px 0px;
}