.card {
  max-width: 864px;
  font-size: 14px;
  color: var(--gray-700);
}

.column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.column.label {
  width: 188px;
  min-width: 188px;
  font-weight: 500;  
}

.row {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.grow {
  width: 100%;
}

.otherEmails {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .otherEmail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .headline {
      font-weight: 500;
      color: var(--gray-700);
    }

    .info {
      font-weight: 400;
      color: var(--gray-500);
    }

    .buttons {
      display: flex;
      gap: 12px;
    }
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .contact {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
  }
}

.modal {
  max-width: 640px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.insuranceContainer {
  margin-top: 8px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid var(--gray-300);

  .logoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logos {
      display: flex;
      gap: 8px;
    
      svg + svg {
        padding-left: 8px;
        border-left: 1px solid var(--gray-300);
        height: 110%;
      }
    }
  }
}