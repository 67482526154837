.pageProjectStartContainer {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.notificationBadge {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: var(--red-500);
  font-size: 10px;
  display: grid;
  place-items: center;
  color: var(--white);
}

.pageProjectStartContainer .MuiTable-root tr.MuiTableRow-root.MuiTableRow-head th:nth-child(1),
.pageProjectStartContainer .supplier-db-main-table-wrap .MuiTable-root tr.MuiTableRow-root.MuiTableRow-head th:nth-child(3) {
  display: table-cell !important;
}
.pageProjectStartContainer .MuiButtonBase-root.MuiTableSortLabel-root {
  display: flex;
  justify-content: space-between;
}
.pageProjectStartContainer .MuiTableSortLabel-root:hover,
.pageProjectStartContainer .MuiTableSortLabel-root:focus {
  color: unset;
}
.pageProjectStartContainer .MuiTableSortLabel-root.Mui-active {
  color: #ff5e15;
}
.pageProjectStartContainer .MuiTableSortLabel-root:not(.Mui-active) .MuiSvgIcon-root {
  display: none;
}
.supplier-pagination {
  display: flex;
  justify-content: center;
}

.tableCustomizationContainer {
  position: relative;
}
.tableCustomizationContainer .tableCustomizationMenu {
  position: absolute;
  top: calc(100%);
  right: 0;

  display: none;
  flex-direction: column;
  padding: 0 10px;
  padding-top: 10px;
  width: 350px;
  background-color: var(--white);

  border-radius: 4px;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.1);

  z-index: 5;
}
.tableCustomizationContainer .tableCustomizationMenu.open {
  display: flex;
}

.tableCustomizationContainer .tableCustomizationMenu > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  gap: 10px;
  
  height: 46px;
  border: 1px solid var(--gray-300);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  border-radius: 6px;
}
.tableCustomizationContainer .tableCustomizationMenu > li > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.tableCustomizationContainer label.ar-label.ar-label-checkbox .react-toggle {
  margin: 0;
}

.pageProjectStartContainer .MuiTableBase-root {
  font-weight: 500;
}
.pageProjectStartContainer .MuiTableCell-root {
  padding: 12px 16px !important;
}
.pageProjectStartContainer .MuiTableRow-root {
  font-size: 14px;
  color: var(--gray-700) !important;
}
.pageProjectStartContainer .MuiTableCell-head {
  color: var(--gray-900) !important;
  font-weight: 500;
  padding: 10px 16px !important;
  position: static;
  background-color: var(--gray-50) !important;
}
.pageProjectStartContainer .projectTypeContainer {
  background-color: var(--gray-100);
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  border-radius: 50%;
}
.pageProjectStartContainer .projectColumn {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 500;
}
.pageProjectStartContainer .projectColumn > div:first-child {
  display: flex;
  align-items: center;
  gap: 15px;
}

.pageProjectStartContainer .MuiTableCell-head:first-child {
  border-top-left-radius: 7px;
}
.pageProjectStartContainer .MuiTableCell-head:last-child {
  border-top-right-radius: 7px;
}

.pageProjectStartContainer .MuiTableBody-root .MuiTableRow-root:not(.MuiTableRow-hover):only-child {
  height: 460px !important;
}
.pageProjectStartContainer .MuiTableBody-root .MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: rgba(245, 245, 245, 0.4) !important;
}
.projectStartDeleteProject {
  visibility: hidden;
}
.pageProjectStartContainer .MuiTableBody-root .MuiTableRow-root:not(:only-child):hover .projectStartDeleteProject {
  visibility: visible;
}
.pageProjectStartContainer .MuiTableCell-head:last-child {
  width: 50px !important;
}
.pageProjectStartContainer .MuiPaper-root > div > div {
  border-radius: 6px;
}
.pageProjectStartContainer .MuiTableRow-head {
  border-bottom: 1px solid var(--gray-200);
}
.pageProjectStartContainer .areaDisplayContainer {
  display: flex;
  width: fit-content;
  height: 35px;
  width: 100%;
  padding: 5px;
}
.pageProjectStartContainer .areaDisplayContainer .barContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.pageProjectStartContainer .areaDisplayContainer .barContainer > div {
  width: 100%;
  height: 4px;
}

.pageProjectStartContainer .areaDisplayContainer .barContainer:first-child > div {
  border-radius: 4px 0 0 4px;
}
.pageProjectStartContainer .areaDisplayContainer .barContainer:last-child > div {
  border-radius: 0 4px 4px 0;
}
.pageProjectStartContainer .areaDisplayContainer .barContainer:only-child > div {
  border-radius: 4px;
}

.pageProjectStartContainer .areaDisplayContainer .barOne {
  background-color: var(--darkBlue);
}
.pageProjectStartContainer .areaDisplayContainer .barTwo {
  background-color: var(--sunglowYellow);
}
.pageProjectStartContainer .areaDisplayContainer .barThree {
  background-color: var(--crayolaGreen);
}
.pageProjectStartContainer .areaDisplayContainer .barFour {
  background-color: var(--gray-500);
}

.pageProjectStartContainer .areaDisplayContainer .barContainer span {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}
.pageProjectStartContainer .areaDisplayContainer .barContainer .barOne + span {
  color: var(--darkBlue);
}
.pageProjectStartContainer .areaDisplayContainer .barContainer .barTwo + span {
  color: var(--orange-500);
}
.pageProjectStartContainer .areaDisplayContainer .barContainer .barThree + span {
  color: var(--amazonGreen);
}
