.container {
  display: grid;
  align-items: center;
  margin: 30px;
  gap: 20px;

  .content {
    min-width: 600px;
    width: 100%;
    max-width: 1180px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    strong {
      color: var(--gray-900);
      font-weight: 500;
    }

    .supplierContainer {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 25px;

      &:not(:last-child) {
        border-bottom: 1px solid var(--gray-300);
      }
      &.isBlacklisted {
        .supplierNameContainer > strong,
        .blackListIcon,
        .memberContainerDetails {
          opacity: .5;
        }
      }

      .supplierNameContainer {
        display: flex;
        align-items: center;
        gap: 10px
      }

      .supplierMembersContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
  
        .memberContainer {
          display: flex;
          gap: 15px;
          width: 100%;
          letter-spacing: normal;
          cursor: pointer;
          margin-left: -9px;
    
          .memberContainerDetails {
            width: 100%;
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-areas:
              'name email'
              'title phoneNumber';
            
            span {
              font-size: 12px;
              color: var(--gray-500);
            }
            strong {
              font-size: 14px;
            }
      
            &:nth-child(1) {
              grid-area: name;
            }
            &:nth-child(2) {
              grid-area: title;
            }
            &:nth-child(3) {
              grid-area: email;
            }
            &:nth-child(4) {
              grid-area: phoneNumber;
            }
          }
        }
      }
    }

    .mainForm {
      & > div + div {
        border-top: 1px solid var(--gray-300);
      }
      & > div {
        padding: 24px 32px;
      }

      .titleWrapper {
        & > p:first-child {
          margin-bottom: 12px;
          font-size: var(--fontSize-12);
          color: var(--gray-900);
        }
      }

      .expirationDateContainer {
        display: flex;
        align-items: center;
        gap: 20px;

        span {
          font-size: 12px;
        }

        & > div:first-child {
          display: flex;
          align-items: center;
          gap: 5px;
        }

        .datePicker {
          max-width: 280px;

          input {
            font-size: var(--fontSize-14);
          }

          svg {
            width: 18px;
          }
        }
      }

      .filesContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 13px;
        margin-bottom: 11px;
      }
    }
  }

  .procTipsContainer {
    position: absolute;
    top: -50px;
    right: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--gray-700);
    gap: 5px;

    .icon {
      font-size: 9px;
    }

    a {
      font-size: var(--fontSize-12);
      color: var(--gray-700);
    }
  }

  .emailWrapper {
    font-size: var(--fontSize-14);
    color: var(--gray-700);
    line-height: 20px;
    font-weight: 400;
    padding: 32px;
  }
}
