.spinner {
  .MuiCircularProgress-circle {
    color: var(--gray-500);
  }
  
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &.xxs {
    .spinnerProgress {
      font-size: 8px !important;
    }
  }

  .spinnerProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px !important;
    color: var(--gray-700);
  }
}