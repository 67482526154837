.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .imagePreview {
    width: 100%;
    padding: 16px;
    text-align: center;
    background-color: var(--gray-50);
    border: 1px solid var(--gray-300);
    position: relative;

    svg {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      border: 3px solid var(--white);
      border-radius: 50%;
      height: 26px;
      width: 26px;

      display: none;
      cursor: pointer;

      transition: fill 300ms;
      fill: var(--gray-500);

      &:hover {
        fill: var(--gray-700);
      }
    }

    &:hover svg {
      display: block;
    }
  }
  
  .previewInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      margin: 0;
      font-size: 14px;

      &:last-child {
        color: var(--yellow-500);
      }
    }
  }
}