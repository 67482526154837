.selected-supplier-mod {
  width: 100%;
  height: 54px;
  background: var(--gray-50);
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0px 14px;
  justify-content: space-between;
  font-size: 14px;
}
.selected-supplier-mod-head {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: var(--gray-900);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.vald{
  margin-left: 24px;
  font-weight: 400;
  font-size: 14px;
}

.autoBid-toggle > p {
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-900);
  line-height: 24px;
  margin: 0;
}
.autoBid-toggle-label .react-toggle {
  margin-right: 10px;
}
label.autoBid-toggle-label {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-top: 16px;
}
label.autoBid-toggle-label>p {
  margin: 0px;
}
  .autoBid-toggle-label .react-toggle {
  transform: scale(0.9, 0.9);
  margin-left: 0px !important;
}
.autoBid-toggle-label .react-toggle-track {
  background: var(--gray-300) !important;
}
.autoBid-toggle-label .react-toggle.react-toggle--checked .react-toggle-track {
  background-color: var(--darkBlue-2) !important;
}
.autoBid-toggle-label .react-toggle-thumb {
  border: 1px solid #e5e7eb;
}
.autoBid-toggle-label span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.icon-dropdown-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}
.icon-dropdown-container .supList-remove-icon {
  align-self: flex-end;
  margin: auto 0;
  width: 18px;
  height: 18px;
}
.icon-dropdown-container .selectedSupplierCloseDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.icon-dropdown-container .selectedSupplierCloseDiv span {
  font-size: 14px;
  color: var(--gray-500);
}

.selectedSupplierHorizontalLine {
  width: 100%;
  height: 1px;
  background-color: var(--gray-300);
  margin-top: 30px;
}
.selectedSuppliersDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-bottom: 15px
}
.selectedSupplierInnerDiv {
  display: flex;
  align-items: center;
  gap: 10px
}
.selectedSupplierInnerDiv {
  font-size: 14px;
  color: var(--gray-700);
}

.date-extend-sub-container {
  margin-top: 16px;
}
.icon-dropdown-container .supplierDB__control {
  background: white;
}
.icon-dropdown-container .bt-select {
  border: none;
}
.autoBid-toggle-label p {
  color: #000000;
  letter-spacing: normal;
  /* padding-bottom: 3px; */
}
.date-extend-sub-container input {
  background: white;
}
.border{
  width: 100%;
}
.close-btn-style{
  display: flex;
  align-items: center;
}
.kontact-person{
  display: flex;
  align-items: center;
  gap:10px;
}
.atal-projekt p{
  padding: 5px 9px;
display: inline;
border-radius: 50%;
background-color: var(--gray-100);
}
.sup_name{
  color: var(--gray-900);
  font-weight: 500;
  font-size: 14px;
}
.Utgång{
font-weight: 600;
font-size: 11px;
line-height: 16px;
/* identical to box height, or 145% */
color: var(--gray-800);
letter-spacing: 0.04em;
text-transform: uppercase;

}
.sup_name_head {
  display: flex;
  /* gap:10px; */
  align-items: center;
  font-size: 14px;
}
.sup_name_head span {
  font-weight: 400;
  color: var(--gray-700);
}
.date-extend-sub-container input {
  padding: 12px;
  background-color: transparent;
}
.supplierSelectionCalendarIcon {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none;
  background-color: transparent;
}
.selected-sup-module .supplierDB__menu {
  z-index: 2;
}

.selectSuppliersDropdown {
  width: 200px
}