.datePickerIcon {
  vertical-align: middle;
}
.datePickerIcon * {
  fill: var(--gray-700);
}

.isClearable > div:last-child {
  margin-right: 24px !important;
}

.datePicker {
  &:focus-within :global(.react-datepicker__close-icon) {
    opacity: 1;
    
    transition: opacity 200ms;
  }

  :global(.react-datepicker__close-icon) {
    top: 12px;
    opacity: 0;

    &::after {
      background-color: var(--gray-500);
    }
  }
}