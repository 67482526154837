/*@import "./variables.scss";*/
@import "./normalize.css";
/* Global App CSS ---------------------- Use this file for styles that should be applied to all components. For example, "font-family" within the "body" selector is a CSS property most apps will want applied to all components. Any global CSS variables should also be applied here. */
 html, body {
	 width: 100%;
	 height: 100%;
}
 body {
	 margin: 0px;
	 padding: 0px;
	 
}

/*25-OCT-2019, Added by Abdullah Atta*/
#root, #root > div, #root > div > #content{
	height: 100%;
}

.section-strategy .column-content{
	flex-direction: row;
}
 input::placeholder {
	 color: #001 65;
	 font-weight: normal;
}
 input, label {
	 color: #001 65;
	 
	 font-weight: normal;
}
textarea,
input,
select,
button {
  font-family: inherit;
  color: inherit;
}
 label {
	 letter-spacing: 0.1em;
}
 bt-button button {
	 font-weight: 500;
}
 .page-padding {
	 padding: 0px 80px;
}
 @media only screen and (max-width: 768px) {
	 .page-padding {
		 padding: 0px 20px;
	}
}

 .fadein {
	 -webkit-animation: fadein 0.4s;
	/* Safari, Chrome and Opera > 12.1 */
	 -moz-animation: fadein 0.4s;
	/* Firefox < 16 */
	 -ms-animation: fadein 0.4s;
	/* Internet Explorer */
	 -o-animation: fadein 0.4s;
	/* Opera < 12.1 */
	 animation: fadein 0.4s;
}
 @keyframes fadein {
	 from {
		 opacity: 0;
	}
	 to {
		 opacity: 1;
	}
}
/* Firefox < 16 */
 @-moz-keyframes fadein {
	 from {
		 opacity: 0;
	}
	 to {
		 opacity: 1;
	}
}
/* Safari, Chrome and Opera > 12.1 */
 @-webkit-keyframes fadein {
	 from {
		 opacity: 0;
	}
	 to {
		 opacity: 1;
	}
}
/* Internet Explorer */
 @-ms-keyframes fadein {
	 from {
		 opacity: 0;
	}
	 to {
		 opacity: 1;
	}
}
/* Opera < 12.1 */
 @-o-keyframes fadein {
	 from {
		 opacity: 0;
	}
	 to {
		 opacity: 1;
	}
}

/* Mujahid 06-11-2019 */
.Dropdown-root {
	font-size: 3em;
	margin-top: 10px;
	text-transform: capitalize
}

.slides {
	float: left;	
}

.error {
	width: 100%;
	bottom: -60px;
	transition-property: all;
	transition-duration: 0.2s;
	transition-timing-function: ease-out;
	margin-top: 20px;
	
	color: var(--red-400) !important;
	font-size: 0.85em;
	text-align: center;
	opacity: 0;
	transform: translateY(20px);
  }
  
  .error.show {
	opacity: 1;
	transform: translateY(0px);
  }
 
  .bt-button .loader, bt-button .loader{
	  display: none !important;
  }

  .bt-button.loader-show .loader, bt-button.loader-show .loader {  
	display: initial !important;
  }

	.img-small {
		width: 18px;
	}
	.notification-details {
		display: block !important;
		padding: 0px !important;
	}

	.notification-accordion-container {
		padding: 0px !important;
    background: transparent !important;
    box-shadow: none !important;
	}

	.notification-accordion-container .notification-accordion-summary {
		padding: 0px !important;
	}
	.ar-notification-date-head {
		font-size: 18px;
    color: black;
    font-weight: 500;
	}
	.ar-notification-container {
		padding: 10px 0px 4px 14px;
	}
	.ar-notification-desc-img {
    font-size: 13px;
}
.disabled-btn {
	color: #d4d4d4 !important;
  cursor: default !important;
}
.contact-person-checkbox {
	color: #ff5e15 !important;
}

.heading-hr {
  border-top: 1px solid #E5E7EB;
  margin-bottom: 19px;
}
.mailSignatureError {
	font-family: 'Segoe UI' !important;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
}
.no-text-transform {
	text-transform: none !important;
}
.supplier-table-wrapper {
  width: 100%;
}
.supplier-table-wrapper .tabs {
  margin-right: 10px;
}

.dropped-img-file {
	width: inherit;
  height: inherit;
	border-radius: 50%;
  object-fit: cover;
  background-size: cover;
  overflow: hidden; 
  pointer-events: 'none';
}
.dropped-img-file-container {
	width: 140px;
  height: 140px;
}

.generic-avatar-fit {
	width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}