.dateButton {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  white-space: nowrap;
  cursor: pointer;

  input {
    font-weight: 400 !important;
  }

  & > div {
    border-radius: 6px;
  }

  .datePickerOnTable {
    border-color: transparent;
    background-color: transparent;

    & > div > svg {
      opacity: 0;
      transition: opacity 350ms;
    }

    &.datePickerOnTableStylesWhenContent > div > svg {
      opacity: 1;
    }
  }

  &:hover .datePickerOnTable > div > svg,
  &:focus-within .datePickerOnTable > div > svg {
    opacity: 1;
    
    transition: opacity 250ms, margin-left 500ms, transform 500ms;
  }

  &:has(.datePickerOnTable) {
    :global(.react-datepicker__close-icon) {
      top: 0 !important;
    }

    width: 220px;
  }
}
