.icon {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  background-color: var(--white);
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
}
.checkedIcon {
  background-color: var(--indigo-600);
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));
  box-shadow: none;
  &:before {
    display: block;
    width: 16px;
    height: 16px;
    background-image: radial-gradient(var(--white), var(--white) 28%, transparent 32%);
    content: '';
  }
  input:hover ~ & {
    background-color: var(--indigo-600);
  }
}
.radioBtnContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 1px 0px 1px 4px;
  font-size: var(--fontSize-12);
  font-weight: var(--weight-500);
  color: var(--gray-700);
  letter-spacing: normal;
}