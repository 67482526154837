.container {
  position: relative;

  display: inline-flex;
  align-items: center;
  gap: 12px;

  white-space: nowrap;
  
  border: 1px solid var(--gray-300);
  background-color: var(--white);
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 300ms ease-out;

  &.disabled {
    cursor: default;
  }

  &:hover {
    background-color: var(--gray-100);
  }

  .fileIconContainer {
    position: relative;
    height: 32px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 20px;
      height: 20px;
    }
  }
  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 100%;
    width: fit-content;
    max-width: 200px;

    white-space: nowrap;

    & > div:first-child {
      margin-top: -4px;
    }

    span {
      font-size: 12px;
      color: var(--gray-900);
    }

    & > span {
      color: var(--gray-500)
    }
  }

  &:hover .removeIcon {
    display: block;
  }

  .removeIcon {
    position: absolute;
    top: -2px;
    right: -22px;
    transform: translate(-50%, -50%);

    display: none;
    cursor: pointer;

    transition: fill 300ms;
    fill: var(--gray-500);    

    &:hover {
      fill: var(--gray-700)
    }
  }
}