
.card {
  background-color: var(--light-blue-50);
  color: var(--light-blue-800);
  border: 1px solid var(--gray-300);
  border-radius: 6px;
  margin-top: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 30px;

  .container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    font-weight: 500;

    p {
      font-size: 16px;
      margin: 0;
      font-weight: 500;
    }
    
    .content {
      font-size: 12px;

      ul {
        margin: 0;
        padding: 0 0 0 16px;
      }
    }
  }
}