.app-select-industry-types {
  
  display: block;
  width: 100%;
  max-width: 1188px;
}
.app-select-industry-types.show-edit-tools btn-remove-industry {
  display: block !important;
}
.app-select-industry-types.show-edit-tools .colum-right {
  background-color: #ff5e15;
}
.app-select-industry-types.show-edit-tools .bt-check {
  width: 100%;
}
.app-select-industry-types.show-edit-tools .bt-check:hover .name {
  background-color: #f1f1f5;
}
.app-select-industry-types.show-edit-tools .bt-check label {
  width: 100%;
  cursor: text;
}
.app-select-industry-types.show-edit-tools .bt-check label:after {
  visibility: hidden;
  opacity: 0 !important;
}
.app-select-industry-types.show-edit-tools .bt-check label .name {
  width: 100%;
}
.app-select-industry-types.show-edit-tools .bt-check .checkmark {
  display: none;
}
.app-select-industry-types.show-edit-tools .bt-check .placeholder {
  font-style: italic;
}
.app-select-industry-types.show-edit-tools .bt-check .placeholder:after {
  display: block;
  content: "";
  width: 10%;
  height: 1px;
  background-color: #ff5e15;
}
.app-select-industry-types .buttons-wrapper bt-button {
  float: right;
}
.app-select-industry-types .float-right {
  float: right;
}
.app-select-industry-types .float-left {
  float: left;
}
.app-select-industry-types input {
  letter-spacing: 0.02em;
}
.app-select-industry-types ul,
.app-select-industry-types li {
  padding: 0;
  margin: 0;
}
.app-select-industry-types li {
  list-style-type: none;
}
.app-select-industry-types .bt-check {
  display: inline-block;
  position: relative;
  font-size: 0.8em;
  padding-bottom: 0px;
  width: 100%;
}
.app-select-industry-types .bt-check .checkmark {
  pointer-events: none;
}
.app-select-industry-types .bt-check label {
  letter-spacing: 0.05em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  padding-bottom: 3px;
  display: inline-block;
}
.app-select-industry-types .bt-check label:after {
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  margin: 0 5px;
  position: relative;
  bottom: 10px;
  content: "";
  display: block;
  width: calc(100% - 15px);
  transform: scaleX(0);
  height: 1px;
  background-color: #ff5e15;
}
.app-select-industry-types .bt-check label .name {
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  padding: 10px 10px;
  margin-left: -5px;
  display: inline-block;
}
.app-select-industry-types .bt-check:hover label:after {
  transform: scaleX(1);
}
.app-select-industry-types .bt-check.checked label {
  color: #ff5e15;
}
.app-select-industry-types .bt-check.checked label:after {
  transform: scaleX(1);
}
.app-select-industry-types .bt-check.checked .checkmark {
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.5s both;
}
.app-select-industry-types .bt-check.checked .checkmark__circle {
  animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.app-select-industry-types .bt-check.checked .checkmark__check {
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
}
.app-select-industry-types .colum-left {
  width: 80%;
  background-color: white;
}
.app-select-industry-types .colum-left .content-left {
  padding: 50px 70px;
}
.app-select-industry-types
  .colum-left
  .content-left
  .content-left
  bt-grid
  > bt-row
  > bt-column:last-child
  .industry-items {
  margin-right: 0;
}
.app-select-industry-types .colum-left app-label {
  margin-top: 3.8em;
  display: block;
}
.app-select-industry-types .colum-left app-label:first-child {
  margin-top: 0;
}
.app-select-industry-types .colum-left .buttons-wrapper {
  margin-top: 50px;
}
.app-select-industry-types .colum-left bt-button.btn-edit,
.app-select-industry-types .colum-left bt-button.btn-edit-save,
.app-select-industry-types .colum-left bt-button.btn-edit-cancel {
  display: inline-block;
  background-color: transparent;
  color: #ff5e15;
  padding-left: 0;
}
.app-select-industry-types .colum-left bt-button.btn-edit:hover,
.app-select-industry-types .colum-left bt-button.btn-edit-save:hover,
.app-select-industry-types .colum-left bt-button.btn-edit-cancel:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.app-select-industry-types .colum-left bt-button.btn-edit:active,
.app-select-industry-types .colum-left bt-button.btn-edit-save:active,
.app-select-industry-types .colum-left bt-button.btn-edit-cancel:active {
  color: #e15311;
}
.app-select-industry-types .colum-left bt-button.btn-edit:after,
.app-select-industry-types .colum-left bt-button.btn-edit-save:after,
.app-select-industry-types .colum-left bt-button.btn-edit-cancel:after {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  content: "";
  display: block;
  width: 1%;
  height: 1px;
  background-color: #ff5e15;
}
.app-select-industry-types .colum-left bt-button.btn-edit:hover:after,
.app-select-industry-types .colum-left bt-button.btn-edit-save:hover:after,
.app-select-industry-types .colum-left bt-button.btn-edit-cancel:hover:after {
  width: 100%;
  transform: scaleX(1);
}
.app-select-industry-types .colum-left bt-button.btn-edit-cancel {
  color: #6a6e77;
}
.app-select-industry-types .btn-add {
  float: right;
}
.app-select-industry-types .btn-close {
  position: absolute;
  right: -20px;
  top: -20px;
  padding: 40px;
  cursor: pointer;
  opacity: 0.8;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.app-select-industry-types .btn-close:hover {
  opacity: 1;
}
.app-select-industry-types .colum-right {
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  flex: 0 1 44%;
  background-color: #001659;
}
.app-select-industry-types .colum-right .content-right {
  padding: 50px 70px;
}
.app-select-industry-types .colum-right .content-right .headline {
  margin-bottom: 5px;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: normal;
  display: block;
  color: white;
  letter-spacing: 0.1em;
}
.app-select-industry-types .colum-right .content-right h2 {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 3.4em;
  line-height: 1.5em;
  margin-bottom: 0.65em;
}
.app-select-industry-types .colum-right .content-right p {
  color: white;
  margin: 0;
  margin-top: -10px;
  letter-spacing: 0.1em;
  line-height: 2.2em;
}
.app-select-industry-types .btn-add-item {
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.app-select-industry-types .btn-add-item svg circle {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.app-select-industry-types .btn-add-item:hover svg circle {
  fill: #d64343;
}
.app-select-industry-types .btn-add-item:active {
  transform: scale(0.9);
}
.app-select-industry-types .checkmark {
  position: absolute;
  top: 50%;
  margin-top: -13px;
  margin-left: -4px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  stroke-width: 4;
  stroke: #ff5e15;
  stroke-miterlimit: 10;
}
.app-select-industry-types .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 3;
  stroke-miterlimit: 10;
  stroke: #ff5e15;
  fill: none;
}
.app-select-industry-types .checkmark__check {
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
}
.app-select-industry-types .industry-items {
  margin-right: 0;
}
.app-select-industry-types .industry-items .industry-item {
  position: relative;
}
.app-select-industry-types
  .industry-items
  .industry-item:hover
  btn-remove-industry {
  opacity: 1 !important;
}
.app-select-industry-types .industry-items .industry-item btn-remove-industry {
  cursor: pointer;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  display: none;
  opacity: 0;
  position: absolute;
  padding-right: 10px;
  right: -20px;
  z-index: 1;
  top: 48%;
  transform: translateY(-50%);
  content: " ";
  background-image: url("../../assets/svg/delete-ring.svg");
  height: 14px;
  width: 14px;
  background-repeat: no-repeat;
}
_:-ms-fullscreen .checkmark__circle,
:root .bt-check.checked .checkmark__circle,
_:-ms-fullscreen .checkmark__check,
:root .bt-check.checked .checkmark__check {
  stroke-dashoffset: 0;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.01, 1.01, 1);
  }
}

.floating-action.add-category {
  position: absolute;
  opacity: 0;
  transform: translateX(100%);
  top: calc(50% - 9px);
  transition: all 0.3s;
  margin-left: 70px;
  right: unset;
}

.floating-action.cancel-category {
  position: absolute;
  opacity: 1;
  top: calc(50% - 9px);
  margin-left: 58px;
  right: unset;
}

.btn.btn-dangerous.subArea-dropdown {
  position: absolute;
  opacity: 1;
  top: calc(50% - 14px);
  margin-left: 35px;
  right: unset;
}

.floating-action.add-category.trash {
  position: absolute;
  opacity: 0;
  transform: translateX(100%);
  top: calc(50% - 15px);
  transition: all 0.3s;
  margin-left: 12px;
  right: unset;
}

.floating-action.add-category.trash img {
  width: 16px;
  height: 24px;
  margin-left: 19px;
  margin-bottom: -16px;
}

.subArea-list {
  color: #fff;
  display: flex;
  align-items: center;
}

li.industry-item:hover .floating-action.add-category {
  transform: translateX(0%);
  opacity: 1;
}
.app-select-industry-types .bt-check.sorted {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-size: 0.8em;
  padding-bottom: 0px;
  width: inherit;
}

.name.category {
  color: #ff5e15;
}

.subArea-bullet {
  position: relative;
  top: 10px;
}

.name.subArea {
  font-size: 11px;
}

/* Areas Category Modal - SubArea form and Suggestions */
.industry-items .sub-area-add-form {
  width: 100%;
  position: relative;
}

.industry-items .sub-area-add-form .form {
  display: flex;
  height: 30px;
  align-items: center;
  flex-direction: row;
}

.industry-items .sub-area-add-form .form input {
  margin-right: 5px;
  color: #000;
  padding: 3px 5px;
  min-width: 160px;
  font-size: 13px;
}

.industry-items .sub-area-add-form .form bt-button {
  padding: 3px 20px;
  top: -20px;
}

.industry-items .sub-area-suggestions {
  background: #ddd;
  margin-top: -5px;
  max-width: 160px;
  font-size: 13px;
  margin-bottom: 10px;
}

.industry-items .sub-area-suggestions li {
  padding: 5px;
  margin: 3px 0;
}

.industry-items .sub-area-tooltip {
  position: absolute;
  background: #ddd;
  right: 5px;
  top: 35px;
  width: 250px;
  padding: 10px;
  border-radius: 2px;
  background: #ffffff;
  z-index: 5;
  box-shadow: 0 0 5px 1px #333333;
  font-size: 12px;
}

.subCategory-existError {
  color: red;
  font-weight: bold;
  margin-left: 10px;
}
