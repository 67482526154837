.app-activity-database h2 {
  padding: 0;
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.app-activity-database .add-activity {
  float: right;
  padding-left: 20px;
  padding-right: 20px;
}

.app-activity-database .add-activity svg {
  position: relative;
  margin-right: 10px;
  top: 1px;
}

.app-activity-database .filter-actions {
  margin-top: 20px;
}

.app-activity-database .filter-actions .bt-column.left {
  margin-right: 30px;
}

.app-activity-database .filter-actions .bt-search {
  position: relative;
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid #e2e2ea;
}

.app-activity-database .filter-actions .bt-search .left {
  margin-left: 14px;
  margin-top: 2px;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  margin-right: 15px;
}

.app-activity-database .filter-actions .bt-search .right {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.app-activity-database .filter-actions .bt-search input {
  font-size: 1.1em;
  padding: 11px 20px;
  padding-left: 0;
  width: 100%;
  background-color: transparent;
  -webkit-box-sizing: border-box;
 /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
 /* Firefox, other Gecko */
  box-sizing: border-box;
 /* Opera/IE 8+ */
}

.app-activity-database .filter-actions .bt-search input::placeholder {
  color: #9399a7;
}

.app-activity-database .filter-actions .bt-select-wrapper {
  white-space: nowrap;
}

.app-activity-database .filter-actions .bt-select-wrapper .bt-select {
  margin-right: 2em;
  font-size: 0.95em;
}

.app-activity-database .filter-actions .bt-select-wrapper .bt-select svg path {
  fill: #ff5e15;
}

.app-activity-database .filter-actions .bt-select-wrapper .bt-select .values {
  padding: 14px 15px;
  padding-bottom: 13px;
}

.app-activity-database .filter-actions .bt-select-wrapper .bt-select:last-child {
  margin-right: 0;
}

.app-add-activity textarea {
  height: 150px !important;
}

.column-content {
  padding: 30px;
}
.task-loader {
  margin: 0 auto;
}

.app-activity-database .MuiTableHead-root {
  color: var(--gray-900) !important;
  font-weight: 500;
  padding: 10px 16px !important;
  position: static;
  background-color: var(--gray-50) !important;
}

.app-activity-database .MuiTableBody-root .MuiTableRow-root:hover {
  background-color: hsla(0,0%,96%,.4)!important;
  cursor: pointer;
}

.app-activity-database td.MuiTableCell-root.MuiTableCell-body {
  color: var(--gray-700);
  font-size: var(--fontSize-14);
  font-weight: var(--weight-400);
}
.app-activity-database .MuiTableBody-root .MuiTableRow-root .name {
  color: var(--gray-900);
  font-size: var(--fontSize-16);
  font-weight: var(--weight-600);
  display: flex;
  align-items: center;
}

.app-activity-database .MuiTableBody-root .MuiTableRow-root .name.completed{
  color: var(--gray-500);
  text-decoration: line-through;
}

.app-activity-database span.MuiButtonBase-root.MuiIconButton-root.StyledCheckbox {
  padding: 31px 30px 31px 27px;
}

.app-activity-database .MuiTableCell-root.MuiTableCell-head:first-child {
  border-top-left-radius: 8px;
  padding-left: 23px;
}

.app-activity-database .MuiTableCell-root.MuiTableCell-head:last-child {
  width: 30px !important;
  border-top-right-radius: 8px;
}

.app-activity-database .MuiTableRow-root:last-child {
  width: 30px !important;
}

.app-activity-database .MuiTableBody-root .MuiTableRow-root .due-date {
  color: var(--gray-900);
  font-size: var(--fontSize-14);
  font-weight: var(--weight-600);
}

.ac-task .project-title {
  color: var(--gray-900);
  font-size: var(--fontSize-18);
  font-weight: var(--weight-600);
}
.activityModalContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.app-activity-database .accSelector-container
{
  flex-grow: 1;
  max-width: 200px;
}