.container {
  display: flex;
  gap: 15px;
  flex-direction: column;
  font-size: 12px;
  color: var(--gray-900);
  font-weight: 500;

  &>label {
    letter-spacing: normal;
    color: inherit;
    margin-left: -9px;
    width: fit-content;
    cursor: pointer;

    display: flex;
    align-items: center;

    &>span {
      font-size: 14px;
    }
  }
}