.app-supplier-database {
    -webkit-animation: 0.7s ease 0s normal forwards 1 fadein;
    animation: 0.7s ease 0s normal forwards 1 fadein;
    opacity: 0;
    display: block;
    width: 100%;
    padding-bottom: 70px;
  }
  @keyframes fadein {
    0% {
      opacity: 0;
   }
    20% {
      opacity: 0;
   }
    100% {
      opacity: 1;
   }
  }
  @-webkit-keyframes fadein {
    0% {
      opacity: 0;
   }
    20% {
      opacity: 0;
   }
    100% {
      opacity: 1;
   }
  }
  .app-supplier-database h2 {
    padding: 0;
    margin: 0;
    font-weight: 600;
    letter-spacing: 0.05em;
  }
  .app-supplier-database .dataTables_wrapper .dataTables_paginate {
    
    float: none;
    text-align: center;
    margin-top: 40px;
  }
  .app-supplier-database .dataTables_wrapper .dataTables_paginate .paginate_button.current {
    background: none;
    background-color: #ff5e15;
    border: 0;
    color: white !important;
  }
  .app-supplier-database .edit-popover {
    position: absolute;
    z-index: 1;
    display: inline-block;
    background-color: white;
    border: 1px solid #e2e2ea;
    -webkit-box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.14);
  }
  .app-supplier-database .edit-popover bt-button {
    background-color: transparent;
    display: block;
    color: black;
  }
  .app-supplier-database .edit-popover .remove {
    color: #fe5150;
  }
  .app-supplier-database table.dataTable tbody tr.selected {
    background-color: transparent !important;
  }
  .app-supplier-database table.dataTable tbody tr.selected td {
    color: #ff5e15;
  }
  .app-supplier-database .the-table {
    border-bottom: 0 !important;
  }
  .app-supplier-database .the-table.expanded tbody tr:hover td {
    opacity: 1;
    border-bottom: 1px solid #e2e2ea;
  }
  .app-supplier-database .the-table.expanded tbody td {
    opacity: 0.4;
    transition-duration: 0.2s;
  }
  .app-supplier-database .the-table.expanded tbody tr.active td {
    background-color: #efefef !important;
    border-bottom-color: transparent;
    opacity: 1;
  }
  .app-supplier-database .the-table table {
    border-bottom-color: #e2e2ea !important;
  }
  .app-supplier-database .the-table table thead .sorting, .app-supplier-database .the-table table thead .sorting_asc, .app-supplier-database .the-table table thead .sorting_desc {
    background-position: left !important;
    padding-left: 24px;
    background-position-y: 8px;
  }
  .app-supplier-database .the-table table thead th {
    text-align: left;
    
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.85em;
    letter-spacing: 0.04em;
    color: #4e4e4e;
    border-bottom: 1px solid #e2e2ea;
  }
  .app-supplier-database .the-table table thead th td {
    border: 0;
  }
  .app-supplier-database .the-table table thead th.right {
    text-align: right;
  }
  .app-supplier-database .the-table table tbody tr {
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    background-color: transparent;
  }
  .app-supplier-database .the-table table tbody tr:hover {
    background-color: #efefef;
  }
  .app-supplier-database .the-table table tbody tr:hover .actions .edit {
    opacity: 0.5;
  }
  .app-supplier-database .the-table table tbody td {
    transition-property: all;
    transition-duration: 0.4s;
    transition-timing-function: ease-out;
    color: #737c97;
    background-color: transparent;
    
    font-size: 0.98em;
    padding: 11px 20px;
    font-weight: normal;
    border-top-color: #e2e2ea;
    border-bottom: 1px solid transparent;
    background-color: transparent !important;
  }
  .app-supplier-database .the-table table tbody td:first-child {
    width: 35%;
  }
  .app-supplier-database .the-table table tbody td.contract {
    width: 1%;
  }
  .app-supplier-database .the-table table tbody td.center {
    text-align: center;
  }
  .app-supplier-database .the-table table tbody td img {
    vertical-align: middle;
  }
  .app-supplier-database .the-table table tbody td.right {
    text-align: right;
  }
  .app-supplier-database .the-table table tbody td .actions {
    text-align: right;
    white-space: nowrap;
  }
  .app-supplier-database .the-table table tbody td .actions .pill {
    text-decoration: inherit;
    display: inline-block;
    color: #e6e6e6;
    background-color: #0063bf;
    opacity: 0.9; 
    border-radius: 5px;
    padding: 3px 10px;
    margin-right: 10px;
    font-size: 0.85em;
  }
  .app-supplier-database .the-table table tbody td .actions .pill:last-child {
    margin-right: 0;
  }
  .app-supplier-database .the-table table tbody td .actions img {
    margin-right: 0px;
    max-width: 24px;
  }
  .app-supplier-database .the-table table tbody td .actions a{
    display: inline-block;
  }
  .app-supplier-database .the-table table tbody td .actions a:last-child img {
    margin-right: 0;
  }
  .app-supplier-database .the-table table tbody td .actions .creditsafe {
    background-color: #ee2e24;
  }
  .app-supplier-database .the-table table tbody td .actions .creditsafe img {
    width: 32px;
  }
  .app-supplier-database .the-table table tbody td .actions .edit {
    vertical-align: middle;
    top: 3px;
    position: relative;
    opacity: 0;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .app-supplier-database .the-table table tbody td .actions .edit svg circle {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .app-supplier-database .the-table table tbody td .actions .edit:hover {
    opacity: 1;
  }
  .app-supplier-database .the-table table tbody td .actions .edit:hover svg circle {
    fill: #ff5e15;
  }
  .app-supplier-database .add-supplier,
  .app-supplier-database .add-area {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 10px;
  }
  .app-supplier-database .add-supplier svg,
  .app-supplier-database .add-area svg {
    position: relative;
    margin-right: 10px;
    top: 1px;
  }
  .app-supplier-database .filter-actions {
    margin-top: 50px;
  }
  .app-supplier-database .filter-actions bt-column.left {
    margin-right: 30px;
  }
  .app-supplier-database .filter-actions .bt-search {
    position: relative;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid #e2e2ea;
  }
  .app-supplier-database .filter-actions .bt-search .left {
    margin-left: 14px;
    margin-top: 2px;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    margin-right: 15px;
  }
  .app-supplier-database .filter-actions .bt-search .right {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .app-supplier-database .filter-actions .bt-search input {
    font-size: 1.1em;
    padding: 11px 20px;
    padding-left: 0;
    width: 100%;
    background-color: transparent;
    -webkit-box-sizing: border-box;
   /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
   /* Firefox, other Gecko */
    box-sizing: border-box;
   /* Opera/IE 8+ */
  }
  .app-supplier-database .filter-actions .bt-search input::placeholder {
    color: #9399a7;
  }
  .app-supplier-database .filter-actions .bt-select-wrapper {
    white-space: nowrap;
  }
  .app-supplier-database .filter-actions .bt-select-wrapper .bt-select {
    margin-right: 2em;
    font-size: 0.95em;
  }
  .app-supplier-database .filter-actions .bt-select-wrapper .bt-select svg path {
    fill: #ff5e15;
  }
  .app-supplier-database .filter-actions .bt-select-wrapper .bt-select .values {
    padding: 14px 15px;
    padding-bottom: 13px;
  }
  .app-supplier-database .filter-actions .bt-select-wrapper .bt-select:last-child {
    margin-right: 0;
  }
  .app-supplier-database .the-table {
    margin-top: 40px;
  }
  .app-add-supplier textarea {
    height: 150px !important;
}
#reason-think-bubble {
  display: none;
  width: 364px;
  height: 241px;
  background: #ffffff;
  position: absolute;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  bottom: 65px;
  left: 0px;
  filter: drop-shadow(-3px 4px 6px var(--gray-400));
  padding: 20px;
}
#reason-think-bubble:after {
  content: "";
  position: absolute;
  left: 10%;
  transform: rotate(0deg);
  bottom: -51px;
  width: 0;
  height: 0;
  border-top: 80px solid #ffffff;
  border-right: 100px solid transparent;
}
.reasonList-supplier-database {
  margin-top: 0px;
  min-height: 190px;
  max-height: 190px;
  word-wrap: break-word;
  overflow-y: scroll;
}
.reasonDate {
  color: #9E9E9E;
}
.blacklistWarningHover{
  position: relative;
  padding: 0px 0px 0px 0px;
}
.blacklistWarningHover:hover #reason-think-bubble{
  display: block;
}
.autoQuoteCheckbox {
  display: flex;
  align-items: center;
}
.autoQuoteCheckbox div.react-datepicker-wrapper {
  width: unset;
  cursor: pointer;
}

.date-extend-container > svg {
  margin-right: 4px;
}

.date-extend-container > p > span {
  font-size: 14px;  
  font-weight: normal;
  color: var(--gray-700);
}
.date-extend-container > p {
  font-weight: 500;
  font-size: 16px;
  color: var(--gray-900);
  margin: 0;
  line-height: 24px;
}
.sup-pagination {
  align-self: center;
}

.MuiTableCell-root .Mui-checked {
color: var(--orange-500) !important;
}
.fas.fa-star:after{
color: var(--orange-500);
}
.far.fa-star:after{
  color: #C8C8C8;
}

.MuiTableCell-root .MuiIconButton-root:hover{
  background-color: transparent !important;
}
.sup-pagination.selected-supplier-mod-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 32px 30px 20px 30px;
  padding: 32px;
  background-color: white;
  border: 1px solid var(--gray-300);
  border-radius: 6px;
  gap: 24px;
}
.sp-top-actions .tab-container {
  flex: 1;
}	
.supplier-database-searchBar-container {
  flex-wrap: wrap;
}
.supplier-database-searchBar-container .searchBarContainer {
  width: 100%;
}
.supplier-database-searchBar-container .accSelector-container {
  flex: 1 1 0;
}
@media only screen and (min-width: 1600px) {
  .supplier-database-searchBar-container {
    flex-wrap: unset;
  }
  .supplier-database-searchBar-container .searchBarContainer {
    width: unset;
  }  
}

