.tipsContainer {
  height: 100%;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
}

.tipsContainer .header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}
.tipsContainer.editing .header button {
  display: none;
}

.tipsContainer .buttonDiv {
  align-self: flex-end;
  display: none;
  margin-top: 20px;
}
.tipsContainer.editing .buttonDiv {
  display: initial;
}

.tipsContainer .editor {
  height: 100% !important;
  min-height: 1000px;
  cursor: default;
}
.tipsContainer.editing .editor {
  cursor: text;
}

.tipsContainer .wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.15);
}

.tipsContainer .ar-button {
  height: fit-content;
  padding: 9px 17px !important;
}