.searchContent {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  z-index: 5;

  .section {
    padding: 8px;
    padding-top: 0;
    width: 100%;
    border-bottom: 1px solid var(--gray-300);
    font-size: 14px;
    display: flex;
    flex-direction: column;

    &:last-child {
      border-bottom: none;
    }

    p {
      font-weight: 500;
    }
    span {
      padding-left: 8px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-weight: 400;
      border-radius: 4px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;

      &.highlighted {
        background-color: var(--gray-100);
      }
    }
  }
}