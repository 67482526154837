.bt-select {
  position: relative;
  border: 1px solid #e2e2ea;
  display: inline-block;
  border-radius: 3px;
  background-color: white;
  margin-right: 2em;
  font-size: .95em;
}
.bt-select:last-child{
  margin-right: 0px;
}
.bt-select .wrapper {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.bt-select .values {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  border-right: 1px solid #e2e2ea;
  padding: 14px 15px;
  padding-bottom: 13px;
}
.bt-select .values .description {
  color: #696974;
  margin-right: 0.3em;
  font-size: 0.9em;
}
.bt-select .values .labelValue {
  font-weight: 500;
  color: #171725;
}
.bt-select .icon-down {
  padding: 8px 10px;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.bt-select .icon-down .icon {
  position: relative;
  top: -2px;
}
.bt-select select {
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.bt-select svg path {
  fill: #ff5e15;
}
#data-table tr th.right {
  display: none;
}