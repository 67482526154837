.rating-component{
    display: flex;
    gap: 7px;
  }
  .rating-component{
    width: max-content;
  }
  .rating-component .fa-star{
    width: 22px !important;
  }
  .rating-number{
      color: var(--gray-700);
  }