.container {
  display: grid;
  gap: 20px;
  width: 90%;
  color: var(--gray-900);
  & > p {
    margin-top: 30px;
    font-size: var(--fontSize-12);

    a {
      color: var(--gray-900);
    }
  }

  .content {
    display: grid;
    grid-template-areas: 
    "one one one"
    "two two three"
    "four four four"
    "five five five";

    background-color: var(--white);
    border-radius: 4px;
    border: 1px solid var(--gray-300);
    

    section {
      padding: 30px;
      border-bottom: 1px solid var(--gray-300);

      & > p {
        font-weight: 500;
        margin: 5px 0;
        font-size: var(--fontSize-16);
      }
      & > span {
        font-size: var(--fontSize-12);
      }

      &.info {
        grid-area: one;

        span {
          font-weight: 400;
        }
      }
      &.areas {
        grid-area: two;
        border-right: 1px solid var(--gray-300);
      }
      &.kommun {
        grid-area: three;
        &.spanAcross {
          grid-column: 1 / -1;
        }
      }
      &.liability {
        grid-area: four;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .liabilityContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          bt-filetype {
            display: flex;
            align-items: center;
            height: 28px;
            border: 1px solid var(--gray-300);
            border-radius: 4px;
            padding: 10px;

            a {
              display: flex;
              align-items: center;
              & > div:last-child {
                margin: 0;
              }
            }

            img {
              width: 16px;
              height: 16px;
            }
          }
        }

        span {
          color: var(--gray-500);
        }
      }
      &.members {
        grid-area: five;
        display: grid;
        gap: 30px;

        .addMemberContainer {
          display: flex;
          align-items: center;
          justify-items: center;
          gap: 10px;
          width: fit-content;
          cursor: pointer;
        }
        .buttonContainer {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 15px;
        }
      }

      &:last-child {
        border: 0;
      }
    }
  }
}