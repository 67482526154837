.app-chat-mail-batch {
  
  display: block;
  width: 100%;
  max-width: 1188px;
}
.app-chat-mail-batch input {
  letter-spacing: 0.02em;
}
.app-chat-mail-batch .colum-left {
  width: 80%;
  background-color: white;
}
.app-chat-mail-batch .colum-left .content-left {
  padding: 50px 70px;
}
.app-chat-mail-batch .colum-left app-label {
  margin-top: 3.8em;
  display: block;
}
.app-chat-mail-batch .colum-left app-label:first-child {
  margin-top: 0;
}
.app-chat-mail-batch .colum-left bt-button {
  margin-top: 40px;
  float: right;
}
.app-chat-mail-batch .colum-left bt-button.bt-align-left {
  float: left;
}
.app-chat-mail-batch .colum-right {
  flex: 0 1 44%;
  background-color: #001659;
}
.app-chat-mail-batch .colum-right .content-right {
  padding: 50px 70px;
}
.app-chat-mail-batch .colum-right .content-right .headline {
  margin-bottom: 5px;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: normal;
  display: block;
  color: white;
  letter-spacing: 0.1em;
}
.app-chat-mail-batch .colum-right .content-right h2 {
  
  margin: 0;
  padding: 0;
  color: white;
  font-size: 2.4em;
  margin-bottom: 0.65em;
}
.app-chat-mail-batch .colum-right .content-right p {
  color: white;
  margin: 0;
  margin-top: -10px;
  letter-spacing: 0.1em;
  line-height: 2.2em;
}
.app-chat-mail-batch .checked-suppliers-users {
  margin-bottom: 50px;
}
.app-chat-mail-batch .checked-suppliers-users label {
  color: white;
  margin-bottom: 10px;
  display: block;
}
.app-chat-mail-batch .checked-suppliers-users .user-avatar bt-avatar {
  top: 0;
}
.app-chat-mail-batch .suppliers-wrapper {
  overflow-y: scroll;
  height: 520px;
}
.app-chat-mail-batch .contract-wrapper {
  overflow-y: scroll;
  height: 468px;
}
.app-chat-mail-batch .selected-supplier-users-wrapper {
  overflow-y: scroll;
  height: 500px;
}
.app-chat-mail-batch .supplier-wrapper {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e2e2ea;
  margin-right: 30px;
}
.app-chat-mail-batch .supplier-wrapper > label {
  font-weight: bold;
}
.app-chat-mail-batch .supplier-wrapper label {
  font-size: 18px;
  margin-bottom: 30px;
  display: block;
}
.app-chat-mail-batch .user-avatar {
  margin-bottom: 10px;
  width: auto !important;
  height: auto !important;
  display: block;
}
.app-chat-mail-batch .user-avatar bt-avatar {
  vertical-align: top;
  position: relative;
  top: 8px;
}
.app-chat-mail-batch .user-avatar .info {
  display: inline-block;
  margin-left: 10px;
  margin-top: 5px;
}
.app-chat-mail-batch .user-avatar p {
  margin: 0;
}
.app-chat-mail-batch .user-avatar .phone,
.app-chat-mail-batch .user-avatar .email {
  opacity: 0.6;
  font-size: 0.7em;
}
.app-chat-mail-batch bt-check {
  display: block;
  position: relative;
  text-transform: uppercase;
  font-size: 0.9em;
  padding-bottom: 0px;
  border-bottom: 0;
}
.app-chat-mail-batch bt-check .checkmark {
  pointer-events: none;
  position: absolute;
  top: 50%;
  margin-top: -13px;
  margin-left: 10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  stroke-width: 2;
  stroke: #ff5e15;
  stroke-miterlimit: 10;
}
.app-chat-mail-batch bt-check .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #ff5e15;
  fill: none;
}
.app-chat-mail-batch bt-check .checkmark__check {
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
}
.app-chat-mail-batch bt-check label {
  vertical-align: top;
  letter-spacing: 0.05em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  padding-bottom: 3px;
  display: inline-block;
  margin-bottom: 0px;
}
.app-chat-mail-batch bt-check label:after {
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  margin: 0 5px;
  position: relative;
  bottom: 10px;
  content: "";
  display: block;
  width: calc(100% - 15px);
  transform: scaleX(0);
  height: 1px;
  background-color: #ff5e15;
}
.app-chat-mail-batch bt-check:hover label:after {
  transform: scaleX(1);
}
.app-chat-mail-batch bt-check .checkmark {
  width: 50px;
}
.app-chat-mail-batch bt-check label:after {
  display: none;
}
.app-chat-mail-batch bt-check[checked] label {
  color: #ff5e15;
}
.app-chat-mail-batch bt-check[checked] label:after {
  transform: scaleX(1);
}
.app-chat-mail-batch bt-check[checked] .checkmark {
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.5s both;
}
.app-chat-mail-batch bt-check[checked] .checkmark__circle {
  animation: stroke 0.4s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.app-chat-mail-batch bt-check[checked] .checkmark__check {
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
}
.app-chat-mail-batch h2 {
  white-space: nowrap;
}
.app-chat-mail-batch .message label {
  display: block;
  margin-bottom: 10px;
}
.app-chat-mail-batch .message .message-wrapper {
  padding: 15px;
  width: 500px;
  border: 1px solid #e2e2ea;
  max-width: 100%;
}
.app-chat-mail-batch .message .signature {
  padding: 0px;
  padding-top: 0px;
}
.app-chat-mail-batch .message .signature:hover pre {
  border-color: #ff5e15;
}
.app-chat-mail-batch .message .signature pre {
  transition-property: border-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  position: relative;
  left: -6px;
  padding-left: 5px;
  border-left: 2px solid transparent;
  margin: 0;
}
.app-chat-mail-batch .btn-send {
  padding-right: 65px;
}
.app-chat-mail-batch .btn-send svg {
  /* margin-left: 47px; */
  left: 87px;
  margin-top: -2px;
  position: absolute;
  width: 20px;
  height: 20px;
  fill: white;
}
.app-chat-mail-batch .btn-attach-wrapper {
  display: inline-block;
  cursor: pointer;
  margin-top: 10px;
  padding: 0 10px;
}
.app-chat-mail-batch .btn-attach-wrapper:hover svg {
  fill: #ff5e15;
}
.app-chat-mail-batch .btn-attach-wrapper:active svg {
  transform: scale(0.9);
}
.app-chat-mail-batch .btn-attach-wrapper svg {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  fill: #bdbdbd;
  width: 23px;
  height: 26px;
}
.app-chat-mail-batch .btn-attach-wrapper input {
  display: none;
}
.app-chat-mail-batch .upload-files-wrapper {
  display: inline;
}
.app-chat-mail-batch .item-overflow {
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
}
.app-chat-mail-batch .item-overflow svg {
  cursor: pointer;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  margin-right: 5px;
  position: relative;
  top: -5px;
}
.app-chat-mail-batch .item-overflow img {
  width: 21px;
}
.app-chat-mail-batch .item-overflow:hover svg {
  opacity: 1;
}
.app-chat-mail-batch .slides {
  margin-top: 40px;
  float: left;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #7a7a7a;
  text-transform: capitalize;
}
.app-chat-mail-batch .slides img,
.app-chat-mail-batch .slides input {
  width: 30px;
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
}
.bt-search {
  position: relative;
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid #e2e2ea;
}
.bt-search .left {
  margin-left: 14px;
  margin-top: 2px;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  margin-right: 15px;
}
.bt-search .right {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}
.bt-search input {
  font-size: 1.1em;
  padding: 11px 20px;
  padding-left: 0;
  width: 100%;
  background-color: transparent;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}
.bt-search input::placeholder {
  color: #9399a7;
}
.grid-demand {
  width: 500px;
}
.app-chat-mail-batch .message .message-wrapper textarea {
  width: 100%;
  height: 280px;
}

.app-chat-mail-batch bt-check .name,
.app-chat-mail-batch .bt-check .name {
  padding: 0px;
}

.app-chat-mail-batch .user-avatar.bt-check > label {
  margin-bottom: 0px;
}
.app-chat-mail-batch .message .message-wrapper .emailTextBox {
  width: 100%;
  height: 150px;

  border: 1px solid #e2e2ea;
  border-radius: 4px;
}

.app-chat-mail-batch .message .message-wrapper.email-wrapper {
  border: 0px;
  display: grid;
  gap: 8px;
}
.app-chat-mail-batch .message .message-wrapper.email-wrapper.bid-request {
  font-size: 14px;
}
.app-chat-mail-batch .message .message-wrapper.email-wrapper ul {
  margin-left: 40px;
}
.app-chat-mail-batch .message .message-wrapper [contenteditable] {
  height: auto !important;
  min-height: auto;
  max-width: inherit;
  overflow: unset !important;
}
.app-chat-mail-batch.custom-editor-style .rdw-editor-main{
  height: 200px !important;
  }
.app-chat-mail-batch .rdw-editor-main {
  height: auto !important;
}
.quotation-main-box .rdw-editor-main {
  height: 95px;
}
.quotation-main-box .DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
  height: auto;
  text-align: initial;
}
.quotation-main-box .rdw-editor-toolbar {
  margin-right: -16px !important;
}

.tipsLink p {
  padding-top: 50px;
  text-decoration: underline;
}