.searchBarWithActions {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;
}
.searchBarWithActions .searchBarContainer {
  background-color: var(--white);
  border-radius: 6px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0 0 0 15px;
  flex-grow: 1;
  border: 1px solid var(--gray-300);
}
.searchBarWithActions.custom .searchBarContainer{
  gap: 5px;

}
.searchBarWithActions .searchBarContainer input {
  height: 100%;
  width: 100%;
  border-radius: inherit;
}
.searchBarWithActions .searchBarContainer input::placeholder {
  color: var(--gray-400);
}