.table {
  line-height: 16px;
  font-size: 14px;
  color: var(--gray-900);
  overflow-y:clip;
  table-layout: fixed;
  min-width: fit-content;
  border-collapse: separate;

  th, td {
    border: 1px solid var(--gray-200);
    border-width: 0 1px 1px 0;
    line-height: 24px;
    padding: 8px;
    position:relative;
  }

  th { 
    width: 200px;
    min-width: 200px;
    text-align: left;
    font-weight: 600;
    position: relative;
  }

  td {
    color: var(--gray-700);
    font-weight: 400;
  }

  tr.empty>td, tr.empty>th { border-width: 0px; }
  tr.empty+tr>td, tr.empty+tr>th { border-top-width: 1px; }
  tr.empty+tr.empty>td, tr.empty+tr.empty>th { border-top-width: 0px; }
  tbody>tr>th:first-child, tbody>tr>td:first-child { border-width: 0 1px 0 0; }
  tbody>tr.empty>th:first-child, tbody>tr.empty>td:first-child { border-right-color: transparent; }
  thead>tr>th:first-child { border-width: 0; }
  thead>tr>th, thead>tr>td { border-width: 0; width: 200px; min-width: 200px; text-align: center; }
  thead.rowBased>tr>th, thead.rowBased>tr>td { width: 120px; min-width: 120px; }
  thead>tr:last-child>th, thead>tr:last-child>td { border-bottom-width: 1px; }
  thead>tr:last-child>th:first-child { border-bottom-width: 0px; }
  tbody>tr>:first-child+td, tbody>tr>:first-child+td { border-left: 1px solid transparent; }
  th:first-child, td:first-child {
    width: 20em;
    position: sticky;
    left: 0;
    z-index: 5;
    background-color: white;
  }
  tbody>th+td { border-left-width: 0;}

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
  }

  div.center {
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  th.supplierName {
    border-width: 0 0 1px 0;
    text-align:center;
    /*  
    position:relative;
    height: 10em;

    div {
      position: absolute;
      bottom: 1em;
      left: 1em;
      width: 22em;
      transform-origin: bottom left;
      transform: rotate(-25deg);
    }
    */
  }

  tr, th, td {
    /*transition: background-color 0.25s ease-in-out;*/
  }
  
  tr:hover>td, tr:hover>th { 
    background-color: #F2F7FA;
  }
  td:hover::after, th:hover::after {
    background-color: rgba(3, 105, 161, 0.05);
    content: '\00a0';  
    height: 10000px;    
    left: -1px;
    position: absolute;  
    top: -5000px;
    width: calc(100% + 2px);  
    border: 1px solid var(--gray-200);
    border-width: 0 1px;
    pointer-events: none;
  }
  tr.empty>td:hover::after, tr.empty>th:hover::after { 
    left: -1px;
    width: calc(100% + 1px);
  }
  tr.empty>td:hover:first-child::after, tr.empty>th:hover:first-child::after { 
    width: calc(100% + 2px);
  }
  td:hover:first-child, th:hover:first-child {
    z-index: 7;
    background-color: #F1F6F8;
  }
}
