.loadingPlaceholder {
  overflow: hidden;
  position: relative;
  z-index: 0;
  background: var(--gray-50);
}

.loadingPlaceholder:before {
  animation: shimmer 1s linear infinite;
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.loadingPlaceholder:before {
  background: linear-gradient(90deg,#0000,#00000008 50%,#0000);
}

.loadingPlaceholder.dark:before {
  background: linear-gradient(90deg,#56565600,#5656561a 50%,#56565600);
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}
