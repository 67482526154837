.container {
  display: grid;
  align-items: center;
  margin: 30px;
  gap: 20px;

  .content {
    min-width: 600px;
    width: 100%;
    max-width: 1180px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    strong {
      color: var(--gray-900);
      font-weight: 500;
    }

    .contractContainer {
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 16px;
      
      & > label {
        max-width: 358px;
      }
    }

    .inputContainer {
      display: flex;
      flex-wrap: wrap;
      margin-top: 12px;
      flex-direction: column;

      
      & > div {
        width: fit-content;
      }
    }
    
    .label {
      font-size: var(--fontSize-14);
      color: var(--gray-700);
      margin-bottom: 7px;
      font-weight: var(--weight-500);
    }

    .description {
      font-size: var(--fontSize-12);
      color: var(--gray-700);
      font-weight: var(--weight-400);
      margin-top: -8px;
    }

    .monitoringCheckbox {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: normal;
      cursor: pointer;

      &.monitored {
        opacity: 0.7;
        color: var(--gray-500);
        cursor: default;

        input {
          cursor: default;
        }
      }
    }

    .emailContainer {
      padding: 30px;
      font-size: 14px;
      color: var(--gray-700);

      hr {
        margin: 25px 0;
        height: 1px;
        border: none;
        background-color: var(--gray-300);
      }

      & > a {
        display: block;
        font-size: 20px;
        margin: 25px 0;
      }

      p {
        margin: 0;

        &.margin {
          margin-bottom: 20px;
        }

        &.bold {
          font-weight: 500;
          color: var(--gray-900);
        }
      }
    }
    .recipients {
      padding: 24px 32px;
      table {
        width: 100%;
        margin-bottom: 24px;
      }
      th, td {
        font-size: 14px;
        width: 33%;
        text-align: left;        
        padding: 16px 16px;
        text-align: left;
       }
       th {
        font-weight: 500;
        color: var(--gray-900);
       }
       th:first-child, td:first-child {
        padding-left: 0;
       }
       th:last-child, td:last-child {
        padding-right: 0;
       }

      .recipient {
        margin-bottom: 16px;
        font-size: 12px;
        border-top: 1px solid var(--gray-200);
        border-bottom: 1px solid var(--gray-200);
        strong {
          font-size: 14px;
        }
        span {
          color: var(--gray-500);
        }  
      }

      & > div:last-of-type { /* Add recipient button */
        margin-top: 8px;
      }
    }

    .questions {
      & > div + div {
        border-top: 1px solid var(--gray-300);
      }
      & > div {
        padding: 24px 32px;
      }

      .titleWrapper {
        & > p:first-child {
          margin-bottom: 12px;
          font-size: var(--fontSize-12);
        }
      }
    }
    .supplierControls {
      font-size: 12px;
      color: var(--gray-700);
      & :global(.inner-sec-title) { display:none; }
    }
    p.supplierControls {
      margin-bottom: 16px;
    }

  }
}
