.app-chat-file-uploads {
    display: block;
    color: #9399a7;
    background-color: white;
}
.app-chat-file-uploads .no-uploads {
    white-space: nowrap;
}
.app-chat-file-uploads .uploads {
    margin-top: 30px;
}
.app-chat-file-uploads .uploads bt-avatar {
    margin-bottom: 12px;
}
.app-chat-file-uploads .uploads:first-child {
    margin-top: 0;
}
.app-chat-file-uploads .uploads .title {
    text-align: center;
    
    font-weight: 600;
    margin: 0;
    color: #44444f;
    font-size: 0.9em;
    margin-bottom: 20px;
}
.app-chat-file-uploads .uploads .content {
    border-bottom: 1px solid #f1f1f5;
    margin-bottom: 15px;
    padding-bottom: 15px;
}
.app-chat-file-uploads .uploads .content .date {
    margin-bottom: 15px;
    font-size: 0.8em;
    text-transform: uppercase;
    text-align: center;
}
.app-chat-file-uploads .uploaded-files>span {
    display: block;
    margin-top: 10px;
}
.app-chat-file-uploads bt-filetype a {
    color: inherit;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    cursor: default;
}
.app-chat-file-uploads bt-filetype a[href] {
    cursor: pointer;
}
.app-chat-file-uploads bt-filetype a:hover[href] {
    color: #ff5e15;
}
.app-chat-file-uploads bt-filetype img {
    width: 20px;
}
.app-chat-file-uploads bt-filetype .description {
    margin-left: 0;
    margin-top: 0;
    font-size: 0.98em;
    font-weight: 100;
    
}
