.container {
  height: 20px;
  width: fit-content;
  text-align: center;
  min-width: 20px;
  padding: 0 5px;

  font-size: 11px;
  line-height: 20px;
  border-radius: 10px;
  background-color: #fa3e3e;
  color: var(--white);
}